package de.geomobile.portal.articles

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.gridlist.mGridList
import com.ccfraser.muirwik.components.gridlist.mGridListTile
import de.geomobile.portal.*
import de.geomobile.portal.utils.CComponent
import de.geomobile.portal.utils.mToolbarTitle2
import kotlinx.css.*
import kotlinx.html.DIV
import kotlinx.serialization.Serializable
import org.w3c.dom.Node
import react.*
import react.dom.div
import react.router.dom.RouteResultProps
import react.router.dom.route
import react.router.dom.switch
import styled.StyledDOMBuilder
import styled.css


fun RBuilder.articleGrid(
    articles: List<ArticleSmall>,
    reload: () -> Unit,
    dashboard: RBuilder.(screenContent: StyledDOMBuilder<DIV>.() -> Unit) -> Unit
) = child(ArticleGrid::class) {
    attrs.articles = articles
    attrs.reload = reload
    attrs.dashboard = dashboard
}


@Serializable
data class ArticleList(
    val articles: List<ArticleSmall>? = null,
    val lastUpdate: Long
)

private interface IdProp : RProps {
    var articleId: String
}

class ArticleGrid : CComponent<ArticleGrid.Props, ArticleGrid.State>() {

    interface Props : RProps {
        var articles: List<ArticleSmall>
        var reload: () -> Unit
        var dashboard: RBuilder.(screenContent: StyledDOMBuilder<DIV>.() -> Unit) -> Unit
    }

    class State(
    ) : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {

        switch {

            route<RProps>("/articles", exact = true) { request ->
                buildElement {
                    with(props) {
                        dashboard {
                            grid(request)
                        }
                    }
                }
            }

            route<IdProp>("/articles/new") { request ->
                articleEdit(
                    null,
                    goBack = {
                        props.reload()
                        request.history.goBack()
                    }
                )
            }

            route<IdProp>("/articles/:articleId/edit") { request ->
                val id: Int = request.match.params.articleId.toInt()
                articleEdit(
                    id,
                    goBack = {
                        props.reload()
                        request.history.goBack()
                    }
                )
            }

            route<IdProp>("/articles/:articleId") { request ->
                val id: Int = request.match.params.articleId.toInt()
                articleDetail(
                    articleId = id,
                    goBack = { request.history.goBack() },
                    reload = { props.reload() },
                    edit = { request.history.push("/articles/$id/edit") }
                )
            }

        }

    }

    private fun StyledDOMBuilder<DIV>.grid(request: RouteResultProps<RProps>) {
        css {
            padding(1.spacingUnits)
        }

        mFab("add", color = MColor.secondary, onClick = {
            request.history.push("/articles/new")
        }) {
            css {
                position = Position.absolute
                right = 4.spacingUnits
                bottom = 4.spacingUnits
                zIndex = currentTheme.zIndex.drawer + 1
            }
        }

        mGridList(cellHeight = 160 + 16) {
            css {
                padding(2.spacingUnits)
            }
            for (article in props.articles.sortedByDescending { it.releasedAt ?: it.createdAt }) {

                mGridListTile(key = article.id.toString()) {
                    articleCard(article, onClick = { request.history.push("/articles/${article.id}") })
                }
            }
        }
    }

}
