package de.geomobile.portal

import de.geomobile.portal.auth.AuthenticationRequiredObserver
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import org.w3c.dom.set
import kotlin.browser.localStorage

object UserStore {

    val reloadListeners = mutableListOf<() -> Unit>()

    var user: PortalUserDTO? = try {
        localStorage["user"]?.let { Json.parse(PortalUserDTO.serializer(), it) }
    } catch (e: Exception) {
        null
    }
        set(value) {
            if (value == null) {
                localStorage.removeItem("user")
            } else {
                localStorage["user"] = Json.stringify(PortalUserDTO.serializer(), value)
            }
            field = value
        }

    var token: String? = try {
        localStorage["token"]
    } catch (e: Exception) {
        null
    }
        set(value) {
            if (value == null) {
                localStorage.removeItem("token")
            } else {
                localStorage["token"] = value
            }
            field = value
        }

    val authenticationRequiredObserver = AuthenticationRequiredObserver {
        clear()
        triggerReload()
    }

    fun triggerReload() = reloadListeners.forEach { it() }

    fun clear() {
        token = null
        user = null
    }
}