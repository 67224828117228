package de.geomobile.portal.admin.users

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.table.*
import de.geomobile.portal.*
import de.geomobile.portal.utils.CComponent
import de.geomobile.portal.utils.mToolbarTitle2
import kotlinx.coroutines.*
import kotlinx.css.*
import react.*
import react.dom.p
import styled.css
import kotlin.coroutines.CoroutineContext

fun RBuilder.adminUserList(
    goBack: () -> Unit,
    userSelected: (userId: String) -> Unit,
    createUser: () -> Unit
) = child(AdminUserList::class) {
    attrs.goBack = goBack
    attrs.userSelected = userSelected
    attrs.createUser = createUser
}

class AdminUserList : CComponent<AdminUserList.Props, AdminUserList.State>() {

    private var loadPageJob: Job = Job()

    interface Props : RProps {
        var goBack: () -> Unit
        var userSelected: (userId: String) -> Unit
        var createUser: () -> Unit
    }

    class State(
        var users: Page<PortalUserDTO>? = null,
        var page: Int = 0,
        var rowsPerPage: Int = 10,
        var count: Int = 0
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        loadPage(state.page, state.rowsPerPage)
    }

    private fun loadPage(page: Int, rowsPerPage: Int) {
        setState {
            this.page = page
            this.rowsPerPage = rowsPerPage
            this.users = null
        }
        loadPageJob.cancel()
        loadPageJob = launch {
            val users = withContext(Dispatchers.Default) {
                restApi.get("/admin/users?limit=$rowsPerPage&start=${page * rowsPerPage}", Page.serializer(PortalUserDTO.serializer()))
            }

            setState {
                this.users = users
                this.count = users.total
            }
        }
    }

    override fun RBuilder.render() = screen {
        val users = state.users

        screenToolbar {
            mIconButton(
                "arrow_back",
                color = MColor.inherit,
                onClick = { props.goBack() }
            )
            mToolbarTitle2("Adminverwaltung")
        }

        screenContent {

            css { padding(4.spacingUnits) }

            mCard(raised = false) {
                css {
                    maxWidth = 1000.px
                    margin(horizontal = LinearDimension.auto)
                }
                mTable {
                    mTableHead {
                        mTableRow {
                            mTableCell { +"E-Mail" }
                            mTableCell(align = MTableCellAlign.right) { +"Name" }
                            mTableCell(align = MTableCellAlign.right) { +"Status" }
                        }
                    }
                    mTableBody {
                        if (users == null) {
                            mTableRow {
                                mTableCell(colSpan = 99, align = MTableCellAlign.center) {
                                    mCircularProgress { css { margin(2.spacingUnits) } }
                                }
                            }
                        } else if (users.total == 0) {
                            mTableRow {
                                mTableCell(colSpan = 99, align = MTableCellAlign.center) {
                                    +"empty"
                                }
                            }
                        } else {
                            for (user in users.rows) {
                                mTableRow(
                                    key = user.email,
                                    hover = true,
                                    onClick = { props.userSelected(user.id) }
                                ) {
                                    mTableCell { +user.email }
                                    mTableCell(align = MTableCellAlign.right) { +(user.name) }
                                    mTableCell(align = MTableCellAlign.right) { +(user.state(short = true)) }
                                }
                            }
                        }
                    }
                    mTableFooter {
                        mTableRow {
                            mTablePagination(
                                labelRowsPerPage = buildElements { p { +"Zeilen pro Seite" } },
                                count = state.count,
                                rowsPerPage = state.rowsPerPage,
                                page = state.page,
                                onChangePage = { _, page ->
                                    loadPage(page = page, rowsPerPage = state.rowsPerPage)
                                },
                                onChangeRowsPerPage = { event ->
                                    loadPage(page = 0, rowsPerPage = event.targetValue as Int)
                                }
                            )
                        }
                    }
                }

                mCardContent {
                    mButton(
                        "Admin hinzufügen",
                        variant = MButtonVariant.contained,
                        color = MColor.secondary,
                        onClick = { props.createUser() }
                    ) {
                        css {
                            marginLeft = LinearDimension.auto
                            margin(top = 1.spacingUnits)
                        }
                    }
                }
            }
        }

    }
}
