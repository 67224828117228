package de.geomobile.portal

import com.ccfraser.muirwik.components.*
import de.geomobile.portal.admin.users.adminUsers
import de.geomobile.portal.appusers.appUserList
import de.geomobile.portal.articles.*
import de.geomobile.portal.auth.AuthenticationProviderInterceptor
import de.geomobile.portal.errorhandling.ApiErrorInterceptor
import de.geomobile.portal.utils.CComponent
import de.geomobile.portal.utils.mToolbarTitle2
import de.geomobile.portal.utils.pluralize
import kotlinx.coroutines.*
import kotlinx.html.DIV
import org.w3c.dom.Node
import org.w3c.notifications.GRANTED
import org.w3c.notifications.Notification
import org.w3c.notifications.NotificationPermission
import react.RBuilder
import react.RProps
import react.RState
import react.router.dom.RouteResultProps
import react.router.dom.redirect
import react.router.dom.route
import react.router.dom.switch
import react.setState
import styled.StyledDOMBuilder
import kotlin.browser.document
import kotlin.browser.window

fun RBuilder.loggedIn(
    user: PortalUserDTO,
    onLogout: () -> Unit
) = child(LoggedIn::class) {
    attrs.user = user
    attrs.logout = onLogout
}

class LoggedIn : CComponent<LoggedIn.Props, LoggedIn.State>() {

    private var logoutJob: Job = Job()
    private var pollJob: Job = Job()

    interface Props : RProps {
        var user: PortalUserDTO
        var logout: () -> Unit
    }

    class State(
        var menuAnchor: Node? = null,
        var articles: List<ArticleSmall> = emptyList()
    ) : RState

    init {
        state = State()
    }

    private fun startPoll() {
        pollJob.cancel()
        pollJob = launch {

            var lastUpdate: Long? = null

            while (isActive) {

                val articles = withContext(Dispatchers.Default) {
                    try {
                        restApi.get("/articles${lastUpdate?.let { "?lastUpdate=$it" } ?: ""}", ArticleList.serializer())
                    } catch (e: Throwable) {
                        println(e.message)
                        null
                    }
                }

                if (articles != null) {
                    if (articles.articles != null) {
                        setState {
                            this.articles = articles.articles
                        }
                    }
                    lastUpdate = articles.lastUpdate
                }

                delay(5000)
            }
        }
    }

    override fun componentDidMount() {
        startPoll()

        launch {

            var notification = false
            var lastNewCount: Int? = null

            while (isActive) {

                if (state.articles.any()) {

                    val newCount = state.articles.sumBy { it.commentStats.created }

                    if (newCount > 0) {
                        document.title = if (notification) {
                            "$newCount " + "neuer Kommentar".pluralize(newCount, plural = "neue Kommentare")
                        } else {
                            "[$newCount] Creactive Citizen"
                        }

                        if (lastNewCount != null && newCount > lastNewCount) {
                            notify(newCount)
                        }

                        notification = !notification
                    } else if (lastNewCount == null || lastNewCount > 0) {
                        document.title = "Creactive Citizen"
                        notification = false
                    }

                    lastNewCount = newCount

                }

                delay(1000)
            }
        }
    }

    private fun notify(count: Int) {
        if (window.asDynamic().Notification == null) {
            println("Browser does not support notifications.")
        } else {
            if (Notification.permission == NotificationPermission.GRANTED) {
                Notification(
                    "Creactive Citizen: $count " + "neuer Kommentar".pluralize(
                        count,
                        plural = "neue Kommentare"
                    )
                )
            } else {
                Notification.requestPermission().then {
                    if (it == NotificationPermission.GRANTED) {
                        Notification(
                            "Creactive Citizen: $count " + "neuer Kommentar".pluralize(
                                count,
                                plural = "neue Kommentare"
                            )
                        )
                    } else {
                        println("User blocked notifications.")
                    }
                }
                    .catch {
                        console.error(it)
                    }
            }
        }
    }

    override fun RBuilder.render() {
        switch {
            route<RProps>("/appusers") { request ->
                appUserList(
                    dashboard = { dashboard(it, request) }
                )
            }

            route<RProps>("/articles") { request ->
                articleGrid(
                    articles = state.articles,
                    reload = { startPoll() },
                    dashboard = { dashboard(it, request) }
                )
            }

            route<RProps>("/admin/users") { request ->
                adminUsers(
                    path = request.match.path,
                    goBack = { request.history.push("/articles") }
                )
            }

            redirect(from = "/", to = "/articles", exact = true)
        }
    }

    private fun RBuilder.dashboard(screenContentHandler: StyledDOMBuilder<DIV>.() -> Unit, request: RouteResultProps<RProps>) {
        screen {

            screenToolbar(
                toolbarContent = {
                    mToolbarTitle2("Dashboard")

                    mIconButton(
                        iconName = "settings",
                        color = MColor.inherit,
                        onClick = {
                            val anchor = it.currentTarget.asDynamic()
                            setState { menuAnchor = anchor }
                        }
                    )
                },
                appBarContent = {
                    mTabs(
                        value = request.match.path,
                        centered = true,
                        onChange = { _, value ->
                            request.history.push(value as String)
                        }
                    ) {
                        mTab(
                            label = "Artikel",
                            value = "/articles"
                        )
                        mTab(
                            label = "App Nutzer",
                            value = "/appusers"
                        )
                    }
                }
            )

            screenContent {
                screenContentHandler()
            }

            mMenu(
                open = state.menuAnchor != null,
                anchorElement = state.menuAnchor,
                onClose = { _, _ -> setState { menuAnchor = null } }) {
                mMenuItem("Adminverwaltung", onClick = { request.history.push("/admin/users") })
                mMenuItem("Abmelden", onClick = { logout() })
            }
        }
    }

    private fun logout() {
        val oldToken = UserStore.token
        UserStore.clear()

        logoutJob.cancel()
        logoutJob = launch {
            withContext(Dispatchers.Default) {
                val logoutApi = restApi.copy(
                    interceptors = listOf(
                        ApiErrorInterceptor,
                        AuthenticationProviderInterceptor { oldToken }
                    )
                )
                logoutApi.delete("/logout")
            }
            props.logout()
        }
    }

}