package de.geomobile.portal.articles

import de.geomobile.portal.CommentUser
import de.geomobile.portal.time.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class Comment(
    val id: Int,
    val createdAt: LocalDateTime,
    val comment: String? = null,
    val image: CommentImage? = null,
    val user: CommentUser? = null,
    val state: State,
    val voting: RatingStats,
    val subComments: List<Comment>
) {
    enum class State {
        CREATED, APPROVED, REJECTED
    }
}

@Serializable
data class CommentImage(
    val fullSizeUrl: String,
    val smallSizeUrl: String
)