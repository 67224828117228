package de.geomobile.portal.articles

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.list.mListItem
import de.geomobile.portal.restApi
import de.geomobile.portal.utils.CComponent
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.css.properties.borderBottom
import kotlinx.css.properties.borderLeft
import kotlinx.css.properties.borderTop
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import org.w3c.files.File
import org.w3c.files.FileList
import org.w3c.files.FileReader
import org.w3c.files.get
import org.w3c.xhr.FormData
import react.RBuilder
import react.RProps
import react.RState
import react.dom.input
import react.setState
import styled.css
import styled.styledDiv
import styled.styledImg


fun RBuilder.articleAddComment(
    articleId: Int,
    parentId: Int?,
    reload: () -> Unit
) = child(ArticleAddComment::class) {
    attrs.articleId = articleId
    attrs.parentId = parentId
    attrs.reload = reload
}

class ArticleAddComment : CComponent<ArticleAddComment.Props, ArticleAddComment.State>() {

    interface Props : RProps {
        var articleId: Int
        var parentId: Int?
        var reload: () -> Unit
    }

    class State(
        var commenting: Boolean = false,
        var addingPhoto: Boolean = false,
        var message: String? = null,
        var imageFile: File? = null,
        var imageUrl: String? = null
    ) : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {
        if (state.commenting) {
            mListItem {

                css {
                    borderTop(1.px, BorderStyle.solid, Color.lightGray)
                    padding(
                        left = 4.spacingUnits,
                        right = 4.spacingUnits,
                        top = 1.spacingUnits,
                        bottom = 1.spacingUnits
                    )
                    if (props.parentId != null) {
                        borderLeft(1.px, BorderStyle.solid, Color.lightGray)
                        borderBottom(1.px, BorderStyle.solid, Color.lightGray)
                        marginLeft = 4.spacingUnits
                        marginBottom = 2.spacingUnits
                        backgroundColor = Color("#efefef")
                    }

                    width = LinearDimension.auto
                    display = Display.flex
                    alignItems = if(state.addingPhoto) Align.center else Align.flexEnd
                }

                mIconButton(
                    "cancel",
                    color = MColor.inherit,
                    onClick = {
                        setState {
                            message = null
                            imageFile = null
                            imageUrl = null
                            commenting = false
                            addingPhoto = false
                        }
                    }
                ) {
                    css { marginBottom = 12.px }
                }

                mTextFieldMultiLine(
                    label = "Neuer Kommentar",
                    value = state.message ?: "",
                    variant = MFormControlVariant.outlined,
                    rows = if (state.addingPhoto) 9 else null,
                    onChange = {
                        val value = it.targetInputValue
                        setState { message = value }
                    }
                ) {
                    css {
                        flexGrow = 1.0
                        marginRight = 2.spacingUnits
                    }
                }




                if (state.addingPhoto) {
                    styledDiv {
                        css {
                            width = 360.px
                            height = 260.px
                            flexShrink = 0.0
                            position = Position.relative
                        }

                        styledImg(src = state.imageUrl) {
                            css {
                                position = Position.absolute
                                top = 0.px
                                left = 0.px
                                width = 100.pct
                                height = 100.pct
                                borderRadius = 8.px
                                objectFit = ObjectFit.contain
                            }
                        }

                        styledDiv {

                            css {
                                position = Position.absolute
                                bottom = 0.px
                                left = 0.px
                                width = 100.pct
                                height = 20.pct
                                backgroundColor = Color.black.withAlpha(0.7)
                                borderBottomLeftRadius = 8.px
                                borderBottomRightRadius = 8.px
                                padding(1.spacingUnits)
                                display = Display.flex
                                alignItems = Align.center
                                justifyContent = JustifyContent.center
                                color = Color.white
                            }

                            input(
                                name = "image",
                                type = InputType.file
                            ) {
                                attrs.id = "image"
                                attrs.accept = "image/*"
                                attrs.onChangeFunction = {
                                    val value = it.target.asDynamic().files as FileList
                                    val reader = FileReader()
                                    val file = if (value.length > 0) value[0] else null

                                    if (file != null) {
                                        reader.onloadend = {
                                            setState {
                                                imageFile = file
                                                imageUrl = reader.result
                                            }
                                        }

                                        reader.readAsDataURL(file)
                                    }
                                }
                            }
                            mIconButton(
                                "delete",
                                color = MColor.inherit,
                                onClick = {
                                    setState {
                                        addingPhoto = false
                                        imageFile = null
                                        imageUrl = null
                                    }
                                }
                            ) {
                                css.color = Color.white
                            }
                        }
                    }
                }

                if (!state.addingPhoto)
                    mIconButton(
                        "add_photo_alternate",
                        onClick = {
                            setState {
                                addingPhoto = true
                            }
                        }
                    ) {
                        css { marginBottom = 12.px }
                    }

                val canSend = !state.message.isNullOrBlank() || state.imageFile != null
                mIconButton(
                    "send",
                    color = if (canSend) MColor.secondary else MColor.inherit,
                    disabled = !canSend,
                    onClick = {
                        sendComment(state.message?.takeIf { it.isNotBlank() }, state.imageFile)
                    }
                ) {
                    css { marginBottom = 12.px }
                }
            }
        } else {
            mListItem(
                button = true,
                onClick = {
                    setState {
                        commenting = true
                        message = ""
                    }
                }
            ) {

                css {
                    borderTop(1.px, BorderStyle.solid, Color.lightGray)
                    padding(
                        left = 4.spacingUnits,
                        right = 4.spacingUnits,
                        top = 1.spacingUnits,
                        bottom = 1.spacingUnits
                    )
                    if (props.parentId != null) {
                        borderLeft(1.px, BorderStyle.solid, Color.lightGray)
                        borderBottom(1.px, BorderStyle.solid, Color.lightGray)
                        marginLeft = 4.spacingUnits
                        marginBottom = 2.spacingUnits
                        backgroundColor = Color("#efefef")
                    }
                    width = LinearDimension.auto
                }

                mTypography("Antworten", color = MTypographyColor.secondary, align = MTypographyAlign.center) {
                    css {
                        width = 100.pct
//                        textAlign = TextAlign.center
                    }
                }
            }
        }
    }

    private fun sendComment(message: String?, imageFile: File?) = launch {

        withContext(Dispatchers.Default) {

            val formData = FormData()
            if (props.parentId != null) formData.append("parentId", props.parentId!!.toString())
            if (message != null) formData.append("comment", message)
            if (imageFile != null) formData.append("image", imageFile)

            restApi.upload(path = "/articles/${props.articleId}/comment", body = formData)
        }

        props.reload()
    }

}