package de.geomobile.portal

import de.geomobile.portal.time.LocalDateTime
import de.geomobile.portal.utils.toText
import kotlinx.serialization.Serializable

@Serializable
data class CommentUser(
    val id: String,
    val name: String,
    val admin: Boolean
)

@Serializable
data class PortalUserDTO(
    val id: String,
    val email: String,
    val name: String,
    val invitedAt: LocalDateTime? = null,
    val active: Boolean
)


fun PortalUserDTO.state(short: Boolean = false): String = when {
    active -> "Aktiv"
    invitedAt != null ->
        if (short) "Eingeladen"
        else "Eingeladen am ${invitedAt.toText(from = de.geomobile.portal.utils.TimeUnit.MINUTE)}"
    else -> "Erstellt"
}
