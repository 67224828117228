package de.geomobile.portal.time

import kotlinx.serialization.*
import kotlin.js.Date

@Serializable(with = LocalDateTimeSerializer::class)
data class LocalDateTime(val millis: Long) : Comparable<LocalDateTime> {

    companion object {
        fun now(): LocalDateTime = LocalDateTime(Date.now().toLong())
        fun parse(encoded: String) = LocalDateTime(Date.parse(encoded).toLong())
    }

    fun format(): String = jsDate.toISOString()

    override fun compareTo(other: LocalDateTime): Int = millis.compareTo(other.millis)

    val jsDate: Date get() = Date(millis)
    val seconds: Long get() = millis / 1000
    val minutes: Long get() = seconds / 60

    fun minusSeconds(amount: Int): LocalDateTime = LocalDateTime(millis - (amount * 1000))
    fun minusMinutes(amount: Int): LocalDateTime = LocalDateTime(millis - (amount * 60 * 1000))
    fun minusHours(amount: Int): LocalDateTime = LocalDateTime(millis - (amount * 60 * 60 * 1000))
    fun minusDays(amount: Int): LocalDateTime {
        val date = jsDate

        return LocalDateTime(
                Date(
                        year = date.getFullYear(),
                        month = date.getMonth(),
                        day = date.getDay() - amount,
                        hour = date.getHours(),
                        minute = date.getMinutes(),
                        second = date.getSeconds(),
                        millisecond = date.getMilliseconds()
                ).getUTCMilliseconds().toLong()
        )
    }

    fun minusMonths(amount: Int): LocalDateTime {
        val date = jsDate

        return LocalDateTime(
                Date(
                        year = date.getFullYear(),
                        month = date.getMonth() - amount,
                        day = date.getDay(),
                        hour = date.getHours(),
                        minute = date.getMinutes(),
                        second = date.getSeconds(),
                        millisecond = date.getMilliseconds()
                ).getUTCMilliseconds().toLong()
        )
    }

    fun minusYears(amount: Int): LocalDateTime {
        val date = jsDate

        return LocalDateTime(
                Date(
                        year = date.getFullYear() - amount,
                        month = date.getMonth(),
                        day = date.getDay(),
                        hour = date.getHours(),
                        minute = date.getMinutes(),
                        second = date.getSeconds(),
                        millisecond = date.getMilliseconds()
                ).getUTCMilliseconds().toLong()
        )
    }

    fun plusSeconds(amount: Int): LocalDateTime = LocalDateTime(millis + (amount * 1000))
    fun plusMinutes(amount: Int): LocalDateTime = LocalDateTime(millis + (amount * 60 * 1000))
    fun plusHours(amount: Int): LocalDateTime = LocalDateTime(millis + (amount * 60 * 60 * 1000))
    fun plusDays(amount: Int): LocalDateTime {
        val date = jsDate

        return LocalDateTime(
                Date(
                        year = date.getFullYear(),
                        month = date.getMonth(),
                        day = date.getDay() + amount,
                        hour = date.getHours(),
                        minute = date.getMinutes(),
                        second = date.getSeconds(),
                        millisecond = date.getMilliseconds()
                ).getUTCMilliseconds().toLong()
        )
    }

    fun plusMonths(amount: Int): LocalDateTime {
        val date = jsDate

        return LocalDateTime(
                Date(
                        year = date.getFullYear(),
                        month = date.getMonth() + amount,
                        day = date.getDay(),
                        hour = date.getHours(),
                        minute = date.getMinutes(),
                        second = date.getSeconds(),
                        millisecond = date.getMilliseconds()
                ).getUTCMilliseconds().toLong()
        )
    }

    fun plusYears(amount: Int): LocalDateTime {
        val date = jsDate

        return LocalDateTime(
                Date(
                        year = date.getFullYear() + amount,
                        month = date.getMonth(),
                        day = date.getDay(),
                        hour = date.getHours(),
                        minute = date.getMinutes(),
                        second = date.getSeconds(),
                        millisecond = date.getMilliseconds()
                ).getUTCMilliseconds().toLong()
        )
    }

    operator fun minus(other: LocalDateTime): ClosedRange<LocalDateTime> = other..this

    override fun toString(): String = format()
}

@Serializer(forClass = LocalDateTime::class)
object LocalDateTimeSerializer : KSerializer<LocalDateTime> {

    override val descriptor: SerialDescriptor = PrimitiveDescriptor("WithCustomDefault", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, obj: LocalDateTime) {
        encoder.encodeString(obj.format())
    }

    override fun deserialize(decoder: Decoder): LocalDateTime {
        return LocalDateTime.parse(decoder.decodeString())
    }
}