package de.geomobile.portal.auth

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.form.mFormControl
import com.ccfraser.muirwik.components.form.mFormGroup
import com.ccfraser.muirwik.components.input.mInput
import com.ccfraser.muirwik.components.input.mInputLabel
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.styles.up
import de.geomobile.portal.errorhandling.ApiException
import de.geomobile.portal.errorhandling.isInvalidSessionError
import de.geomobile.portal.restApi
import de.geomobile.portal.screen
import de.geomobile.portal.utils.CComponent
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.dom.div
import react.setState
import styled.css
import styled.styledDiv
import styled.styledImg
import kotlin.browser.document

fun RBuilder.requestPasswordReset(
    email: String,
    goBack: () -> Unit
) = child(RequestPasswordReset::class) {
    attrs.email = email
    attrs.goBack = goBack
}

class RequestPasswordReset(props: Props) : CComponent<RequestPasswordReset.Props, RequestPasswordReset.State>(props) {

    private var job: Job = Job()

    interface Props : RProps {
        var email: String
        var goBack: () -> Unit
    }

    class State(
        var email: String,
        var sending: Boolean = false,
        var send: Boolean = false,
        var error: String? = null
    ) : RState

    init {
        state = State(email = props.email)
    }

    override fun RBuilder.render() = screen {
        themeContext.Consumer { currentTheme ->
            val sending = state.sending
            val send = state.send
            val error = state.error

            mAppBar(position = MAppBarPosition.absolute, color = MColor.inherit) {
                css {
                    zIndex = currentTheme.zIndex.drawer + 1
                    display = Display.block
                }
                styledImg(alt = "Creactive Citizen Logo", src = "/ivanto_logo_rgb.png") {
                    css {
                        margin(horizontal = LinearDimension.auto)
                        height = 80.px
                        display = Display.block
                    }
                }
            }

            styledDiv {
                css {
                    width = 100.pct
                    display = Display.block
                    marginLeft = 2.spacingUnits
                    marginRight = 2.spacingUnits
                    marginTop = 80.px
                    media(currentTheme.breakpoints.up(Breakpoint.sm)) {
                        width = 400.px
                        marginLeft = LinearDimension.auto
                        marginRight = LinearDimension.auto
                    }
                }

                document.onkeydown = {
                    if (it.keyCode == 13) // User hits enter
                        trySend()
                }

                mPaper {
                    css {
                        marginTop = 8.spacingUnits
                        marginBottom = 1.spacingUnits
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        alignItems = Align.center
                        padding(2.spacingUnits, 3.spacingUnits, 3.spacingUnits)
                    }
                    mAvatar {
                        css {
                            margin(2.spacingUnits)
                            color = Colors.white
                            backgroundColor = Color(currentTheme.palette.secondary.main)
                        }
                        mIcon("vpn_key")
                    }
                    mTypography(text = "Passwort vergessen?", variant = MTypographyVariant.h5)
                    mTypography(
                        text = if (send)
                            "Ein Link zur Wiederherstellung wurde verschickt an:"
                        else
                            "Ein Link zur Wiederherstellung wird verschickt an:"
                    ) {
                        css {
                            marginTop = 2.spacingUnits
                        }
                    }

                    mFormGroup {
                        css {
                            marginTop = 1.spacingUnits
                            width = 100.pct
                        }
                        mFormControl(
                            margin = MMargin.normal,
                            required = true,
                            fullWidth = true,
                            disabled = sending || send
                        ) {
                            mInputLabel(caption = "E-Mail", htmlFor = "email")
                            mInput(
                                value = state.email,
                                name = "email",
                                id = "email",
                                autoComplete = "email",
                                onChange = {
                                    val value = it.targetInputValue
                                    setState { email = value }
                                })
                        }

                        if (sending) {
                            mCircularProgress {
                                css {
                                    marginTop = 3.spacingUnits
                                    marginLeft = LinearDimension.auto
                                    marginRight = LinearDimension.auto
                                }
                            }
                        } else if (!send) {
                            mButton(
                                fullWidth = true,
                                variant = MButtonVariant.contained,
                                color = MColor.primary,
                                caption = "Senden",
                                onClick = { trySend() }
                            ) {
                                css { marginTop = 3.spacingUnits }
                            }
                        }

                        mButton(
                            fullWidth = true,
                            variant = if (send) MButtonVariant.contained else MButtonVariant.text,
                            color = if (send) MColor.primary else MColor.default,
                            caption = "Zurück zum Login",
                            onClick = { props.goBack() }
                        ) {
                            css { marginTop = 3.spacingUnits }
                        }

                        if (error != null) {
                            mSnackbar(
                                error,
                                open = true,
                                horizAnchor = SnackbarHorizAnchor.center,
                                autoHideDuration = 4000,
                                onClose = { _, _ -> setState { this.error = null } },
                                action = RBuilder().div {
                                    mIconButton(
                                        "close",
                                        onClick = { setState { this.error = null } },
                                        color = MColor.inherit
                                    )
                                }
                            )
                        }
                    }
                }
            }
        }
    }

    private fun trySend() {
        job.cancel()
        job = launch {
            setState { sending = true }
            try {
                launch(Dispatchers.Default) {
                    restApi.post(
                        path = "/requestpasswordreset",
                        body = Json.stringify(
                            RequestPasswordResetDTO.serializer(),
                            RequestPasswordResetDTO(email = state.email)
                        )
                    )
                }.join()

                setState {
                    this.sending = false
                    this.send = true
                }

            } catch (e: ApiException) {
                println("LOGIN ERROR: $e")

                val error = if (e.apiError.isInvalidSessionError)
                    "Invalid login credentials"
                else
                    e.message

                setState {
                    this.error = error
                    this.sending = false
                    this.send = false
                }
            }

        }
    }
}

@Serializable
private data class RequestPasswordResetDTO(
    val email: String
)
