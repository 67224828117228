package de.geomobile.portal

import com.ccfraser.muirwik.components.*
import kotlinx.css.*
import kotlinx.html.DIV
import react.RBuilder
import styled.StyledDOMBuilder
import styled.StyledHandler
import styled.css
import styled.styledDiv

fun RBuilder.screen(content: StyledDOMBuilder<DIV>.() -> Unit) {
    styledDiv {
        css {
            height = 100.vh
            display = Display.flex
            flexDirection = FlexDirection.column
            overflow = Overflow.hidden
            backgroundColor = Color("#ebece7")
        }

        content()

    }
}

fun RBuilder.screenToolbar(
    appBarContent: StyledHandler<MAppBarProps>? = null,
    toolbarContent: StyledHandler<MToolbarProps>) {
    mAppBar(position = MAppBarPosition.absolute) {
        mToolbar {
            toolbarContent()
        }
        if (appBarContent != null)
            appBarContent()
    }

    spacer()
    if (appBarContent != null)
        spacer()
}

fun RBuilder.screenContent(
    scrollable: Boolean = true,
    content: StyledDOMBuilder<DIV>.() -> Unit
) {
    styledDiv {
        css {
            flex(1.0, 1.0, FlexBasis.auto)
            if (scrollable)
                overflowY = Overflow.auto
        }

        content()
    }
}