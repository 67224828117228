package de.geomobile.portal.articles

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.*
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import de.geomobile.portal.*
import de.geomobile.portal.utils.CComponent
import kotlinx.coroutines.*
import kotlinx.css.*
import kotlinx.serialization.json.Json
import react.*
import styled.*
import kotlin.math.max


fun RBuilder.articleSurvey(
    articleId: Int,
    survey: Survey?,
    reload: () -> Unit
) = child(ArticleSurvey::class) {
    attrs.articleId = articleId
    attrs.survey = survey
    attrs.reload = reload
}

class ArticleSurvey : CComponent<ArticleSurvey.Props, ArticleSurvey.State>() {

    interface Props : RProps {
        var articleId: Int
        var survey: Survey?
        var reload: () -> Unit
    }

    class State(
        var newSurvey: Survey? = null
    ) : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {
        mCard(raised = false) {
            css { width = 100.pct }

            mCardHeader(title = "Umfrage")

            val survey = state.newSurvey ?: props.survey

            if (survey == null) {
                mCardContent {
                    mButton(
                        "Umfrage erstellen",
                        color = MColor.secondary,
                        variant = MButtonVariant.contained,
                        onClick = {
                            setState {
                                newSurvey = Survey(
                                    active = true,
                                    showResultsInApp = true,
                                    showResultsOnlyToParticipants = false,
                                    participants = 0,
                                    questions = emptyList()
                                )
                            }
                        }
                    )
                }
            } else {
                mCardContent {

                    if (state.newSurvey == null) {
                        mTypography(if (survey.active) "aktiv" else "inaktiv") {
                            if (survey.active) css.color = Color.green
                        }

                        mTypography("Beteiligungen: ${survey.participants}")
                    } else {
                        mCheckboxInLabel(
                            "Umfrageergebnis in den Apps anzeigen",
                            checked = state.newSurvey!!.showResultsInApp,
                            onChange = {_, checked ->
                                setState { newSurvey = survey.copy(showResultsInApp = checked) }
                            }
                        )
                        if (state.newSurvey!!.showResultsInApp)
                        mCheckboxInLabel(
                            "Umfrageergebnis in den Apps nur für Teilnehmer anzeigen",
                            checked = state.newSurvey!!.showResultsOnlyToParticipants,
                            onChange = {_, checked ->
                                setState { newSurvey = survey.copy(showResultsOnlyToParticipants = checked) }
                            }
                        )
                    }

                    questions(
                        surveyQuestions = survey.questions,
                        edit = state.newSurvey != null,
                        onChanged = {
                            setState { newSurvey = newSurvey!!.copy(questions = it) }
                        }
                    )
                }

                mCardActions {
                    if (state.newSurvey != null) {
                        val enabled = state.newSurvey!!.questions.isNotEmpty() &&
                            state.newSurvey!!.questions.all {
                                it.text.isNotBlank() &&
                                    it.answers.isNotEmpty() &&
                                    it.answers.all { it.text.isNotBlank() }
                            }
                        mButton(
                            "Abbrechen",
                            onClick = {
                                clear()
                            }
                        ) {
                            css.marginLeft = LinearDimension.auto
                        }
                        mButton(
                            "Speichern",
                            color = MColor.secondary,
                            disabled = !enabled,
                            onClick = {
                                saveSurvey(state.newSurvey!!.copy(active = false))
                            }
                        )
                        mButton(
                            "Speichern & Umfrage starten",
                            color = MColor.secondary,
                            variant = MButtonVariant.contained,
                            disabled = !enabled,
                            onClick = {
                                saveSurvey(state.newSurvey!!.copy(active = true))
                            }
                        )
                    } else {
                        mButton(
                            if (survey.active) "Umfrage beenden" else "Umfrage starten",
                            color = MColor.secondary,
                            variant = MButtonVariant.contained,
                            onClick = {
                                toggleActive(!survey.active)
                            }
                        ) {
                            css.marginLeft = LinearDimension.auto
                        }
                    }
                }
            }
        }
    }

    private fun RBuilder.questions(surveyQuestions: List<Survey.Question>, edit: Boolean, onChanged: (List<Survey.Question>) -> Unit) {
        mList {
            for ((index, item) in surveyQuestions.withIndex()) {

                mListItem {

                    if (edit) {
                        mTextField("Frage ${index + 1}",
                            value = item.text,
                            fullWidth = true,
                            onChange = {
                                val value = it.targetInputValue
                                onChanged(
                                    surveyQuestions.toMutableList().also { it[index] = it[index].copy(text = value) }
                                )
                            }
                        )
                    } else {
                        mTypography("Frage ${index + 1}: ${item.text}", variant = MTypographyVariant.h5)
                    }

                    mCheckboxInLabel(
                        "Mehrfachauswahl",
                        checked = !item.singleChoice,
                        disabled = !edit,
                        onChange = { _, checked ->
                            onChanged(
                                surveyQuestions.toMutableList().also { it[index] = it[index].copy(singleChoice = !checked) }
                            )
                        }
                    ) {
                        css.marginLeft = LinearDimension.auto
                    }

                    if (edit) {
                        mIconButton("delete", onClick = {
                            onChanged(surveyQuestions.filterIndexed { currentIndex, _ -> currentIndex != index })
                        })
                    }

                }
                mListItem {
                    css { paddingLeft = 8.spacingUnits }
                    surveyItemAnswers(
                        answers = item.answers,
                        edit = edit,
                        onChanged = { answers ->
                            onChanged(
                                surveyQuestions.toMutableList().also { it[index] = it[index].copy(answers = answers) }
                            )
                        }
                    )
                }
            }

            if (edit) {
                mListItem {
                    mButton("Frage hinzufügen",
                        onClick = {
                            onChanged(
                                surveyQuestions.plusElement(Survey.Question(text = "", singleChoice = true, answers = emptyList()))
                            )
                        }
                    )
                }
            }
        }
    }

    private fun RBuilder.surveyItemAnswers(
        answers: List<Survey.Question.Answer>,
        edit: Boolean,
        onChanged: (List<Survey.Question.Answer>) -> Unit
    ) {
        val total = max(1.0, answers.sumBy { it.votes }.toDouble())

        mList {
            css.width = 100.pct

            for ((index, answer) in answers.withIndex()) {

                mListItem {

                    if (edit) {
                        mTextField("Antwort ${index + 1}",
                            value = answer.text,
                            fullWidth = true,
                            onChange = {
                                val value = it.targetInputValue
                                onChanged(
                                    answers.toMutableList().also {
                                        it[index] = it[index].copy(text = value)
                                    }
                                )
                            }
                        )
                    } else {
                        mTypography("Antwort ${index + 1}: ${answer.text}") {
                            css.marginRight = LinearDimension.auto
                        }
                    }

                    if (edit) {
                        mIconButton("delete", onClick = {
                            onChanged(answers.filterIndexed { currentIndex, _ -> currentIndex != index })
                        })
                    } else {
                        println()
                        println(answer.votes.toDouble())
                        println(total)
                        println(answer.votes.toDouble() / total)
                        mTypography("Stimmen: ${answer.votes}") {
                            css.marginRight = 2.spacingUnits
                        }
                        mLinearProgress(100.0 * answer.votes.toDouble() / total, variant = MLinearProgressVariant.determinate) {
                            css { width = 100.px }
                        }
                    }

                }
            }

            if (edit) {
                mListItem {
                    mButton("Antwort hinzufügen",
                        onClick = {
                            onChanged(
                                answers.plusElement(Survey.Question.Answer(""))
                            )
                        }
                    )
                }
            }
        }
    }

    private fun saveSurvey(newSurvey: Survey) {
        launch {
            withContext(Dispatchers.Default) {
                restApi.put("/articles/${props.articleId}/survey", Json.stringify(Survey.serializer(), newSurvey))
            }

            props.reload()
            clear()
        }
    }

    private fun toggleActive(active: Boolean) {
        launch {
            withContext(Dispatchers.Default) {
                if (active)
                    restApi.post("/articles/${props.articleId}/survey/active")
                else
                    restApi.delete("/articles/${props.articleId}/survey/active")
            }

            props.reload()
            clear()
        }
    }

    private fun clear() = setState {
        newSurvey = null
    }
}
