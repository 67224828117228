package de.geomobile.portal.admin.users

import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.router.dom.route
import react.router.dom.switch

fun RBuilder.adminUsers(
    path: String,
    goBack: () -> Unit
) = child(AdminUsers::class) {
    attrs.goBack = goBack
    attrs.path = path
}

class AdminUsers : RComponent<AdminUsers.Props, RState>() {

    interface Props : RProps {
        var goBack: () -> Unit
        var path: String
    }

    override fun RBuilder.render() {
        switch {

            route<RProps>("${props.path}/create", exact = true) { routeProps ->
                adminUserCreate(
                    cancel = { routeProps.history.push(props.path) },
                    created = { routeProps.history.push("${props.path}/$it") }
                )
            }

            route<AdminUserDetail.Props>("${props.path}/:userId/edit", exact = true) { routeProps ->
                val userId = routeProps.match.params.userId
                adminUserEdit(
                    userId,
                    goBack = { routeProps.history.push("${props.path}/$userId") },
                    onDeleted = { routeProps.history.push(props.path) }
                )
            }


            route<RProps>(props.path, exact = true) { routeProps ->
                adminUserList(
                    goBack = { props.goBack() },
                    userSelected = { routeProps.history.push("${props.path}/$it") },
                    createUser = { routeProps.history.push("${props.path}/create") }
                )
            }

            route<AdminUserDetail.Props>("${props.path}/:userId", exact = true) { routeProps ->
                val userId = routeProps.match.params.userId
                adminUserDetail(
                    userId,
                    goBack = { routeProps.history.push(props.path) },
                    edit = { routeProps.history.push("${props.path}/$userId/edit") }
                )
            }
        }
    }
}