package de.geomobile.portal.admin.users

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.mFormControl
import com.ccfraser.muirwik.components.form.mFormGroup
import com.ccfraser.muirwik.components.input.mInput
import com.ccfraser.muirwik.components.input.mInputLabel
import de.geomobile.portal.CommentUser
import de.geomobile.portal.PortalUserDTO
import de.geomobile.portal.screen
import de.geomobile.portal.screenContent
import de.geomobile.portal.screenToolbar
import de.geomobile.portal.restApi
import de.geomobile.portal.utils.CComponent
import de.geomobile.portal.utils.mToolbarTitle2
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css

fun RBuilder.adminUserCreate(
    cancel: () -> Unit,
    created: (userId: String) -> Unit
) = child(AdminUserDetailEdit::class) {
    attrs.userId = null
    attrs.mode = AdminUserDetailEdit.Mode.CREATE
    attrs.cancel = cancel
    attrs.saved = created
    attrs.onDeleted = cancel
}

fun RBuilder.adminUserEdit(
    userId: String,
    goBack: () -> Unit,
    onDeleted: () -> Unit
) = child(AdminUserDetailEdit::class) {
    attrs.userId = userId
    attrs.mode = AdminUserDetailEdit.Mode.EDIT
    attrs.cancel = { goBack() }
    attrs.saved = { goBack() }
    attrs.onDeleted = onDeleted
}

class AdminUserDetailEdit : CComponent<AdminUserDetailEdit.Props, AdminUserDetailEdit.State>() {

    enum class Mode {
        CREATE, EDIT
    }

    interface Props : RProps {
        var userId: String?
        var mode: Mode
        var cancel: () -> Unit
        var saved: (userId: String) -> Unit
        var onDeleted: () -> Unit
    }

    class State(
        var email: String? = null,
        var name: String? = null,
        var setPassword: Boolean = false,
        var password: String = "",
        var passwordRepeat: String = "",

        var saving: Boolean = false
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {

            val user = if (props.userId != null)
                withContext(Dispatchers.Default) {
                    restApi.get("/admin/users/${props.userId}", PortalUserDTO.serializer())
                }
            else
                null

            setState {
                this.email = user?.email
                this.name = user?.name
            }
        }
    }

    override fun RBuilder.render() = screen {

        screenToolbar {

            mToolbarTitle2(
                when (props.mode) {
                    Mode.CREATE -> "Hinzufügen: ${state.email ?: ""}"
                    Mode.EDIT -> "Ändern: ${state.email ?: ""}"
                }
            )

            mButton(
                "Abbrechen",
                color = MColor.inherit,
                onClick = { props.cancel() }
            ) {
                css { marginRight = 1.spacingUnits }
            }

            val disabled = state.email == null ||
                    state.password != state.passwordRepeat ||
                    (state.setPassword && state.password.isBlank())

            mButton(
                when (props.mode) {
                    Mode.CREATE -> "Hinzufügen"
                    Mode.EDIT -> "Speichern"
                },
                variant = MButtonVariant.contained,
                color = MColor.secondary,
                disabled = disabled,
                onClick = {
                    saveUser(
                        id = props.userId ?: "",
                        email = state.email!!,
                        name = state.name!!,
                        password = state.password.takeIf { state.setPassword }
                    )
                }
            )
        }


        screenContent {

            css { padding(4.spacingUnits) }

            mCard(raised = false) {
                css {
                    maxWidth = 1000.px
                    margin(horizontal = LinearDimension.auto)
                }

                mCardContent {
                    if (props.mode == Mode.EDIT && state.email == null)
                        mCircularProgress { css { display = Display.block; margin(LinearDimension.auto) } }
                    else
                        mFormGroup {
                            mFormControl(margin = MMargin.normal, required = true, fullWidth = true) {
                                mInputLabel(caption = "E-Mail", htmlFor = "email")
                                mInput(
                                    value = state.email ?: "",
                                    name = "email",
                                    id = "email",
                                    onChange = {
                                        val value = it.targetInputValue
                                        setState { this.email = value }
                                    }
                                )
                            }
                            mFormControl(margin = MMargin.normal, required = false, fullWidth = true) {
                                mInputLabel(caption = "Name", htmlFor = "name")
                                mInput(
                                    value = state.name ?: "",
                                    name = "name",
                                    id = "name",
                                    onChange = {
                                        val value = it.targetInputValue
                                        setState { this.name = value }
                                    }
                                )
                            }

                            mSwitchInLabel(
                                label =
                                when (props.mode) {
                                    Mode.CREATE -> "Passwort manuell setzen? (Wenn das Passwort nicht manuell gesetzt wird, wird ein Link zum Setzen des Passworts an die E-Mail-Adresse des Benutzers gesendet.)"
                                    Mode.EDIT -> "Passwort ändern"
                                },
                                checked = state.setPassword,
                                onChange = { _, checked ->
                                    setState {
                                        setPassword = checked
                                        if (!checked) {
                                            password = ""
                                            passwordRepeat = ""
                                        }
                                    }
                                }
                            ) {
                                css { marginTop = 6.spacingUnits }
                            }
                            mFormControl(
                                margin = MMargin.normal,
                                required = true,
                                fullWidth = true,
                                disabled = !state.setPassword
                            ) {
                                mInputLabel(caption = "Neues Passwort", htmlFor = "password")
                                mInput(
                                    value = state.password,
                                    name = "password",
                                    id = "password",
                                    type = InputType.password,
                                    onChange = {
                                        val value = it.targetInputValue
                                        setState { this.password = value }
                                    }
                                )
                            }
                            mFormControl(
                                margin = MMargin.normal,
                                required = true,
                                fullWidth = true,
                                disabled = !state.setPassword
                            ) {
                                mInputLabel(caption = "Neues Passwort wiederholen", htmlFor = "passwordRepeat")
                                mInput(
                                    value = state.passwordRepeat,
                                    name = "passwordRepeat",
                                    id = "passwordRepeat",
                                    type = InputType.password,
                                    error = state.password != state.passwordRepeat,
                                    onChange = {
                                        val value = it.targetInputValue
                                        setState { this.passwordRepeat = value }
                                    }
                                )
                            }
                        }
                }
            }
        }
    }

    private fun saveUser(id: String, email: String, name: String, password: String?) {
        setState {
            this.saving = true
        }

        launch {
            val newUser = withContext(Dispatchers.Default) {
                val user = EditUserDTO(
                    email = email,
                    name = name,
                    password = password
                )
                when (props.mode) {
                    Mode.CREATE ->
                        restApi.post(
                            "/admin/users",
                            body = Json.stringify(EditUserDTO.serializer(), user),
                            serializer = CommentUser.serializer()
                        )

                    Mode.EDIT ->
                        restApi.put(
                            "/admin/users/$id",
                            body = Json.stringify(EditUserDTO.serializer(), user),
                            serializer = CommentUser.serializer()
                        )
                }
            }

            props.saved(newUser.id)
        }
    }
}

@Serializable
data class EditUserDTO(
    val email: String,
    val name: String,
    val password: String? = null
)