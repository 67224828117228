package de.geomobile.portal

import de.geomobile.portal.auth.login
import de.geomobile.portal.auth.requestPasswordReset
import de.geomobile.portal.auth.resetPassword
import de.geomobile.portal.utils.params
import de.geomobile.portal.utils.urlDecode
import de.geomobile.portal.utils.urlEncode
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.router.dom.route
import react.router.dom.switch
import kotlin.browser.document

fun RBuilder.loggedOut(
    onLoggedIn: () -> Unit
) = child(LoggedOut::class) {
    attrs.onLoggedIn = onLoggedIn
}

class LoggedOut : RComponent<LoggedOut.Props, LoggedOut.State>() {

    interface Props : RProps {
        var onLoggedIn: () -> Unit
    }

    class State(
        var loading: Boolean = true
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        document.title = "Creactive Citizen"
    }

    override fun RBuilder.render() {

        switch {

            route<RProps>("/resetpassword") { request ->
                val token = request.location.params.getValue("token")
                resetPassword(
                    api = restApi,
                    token = token,
                    resetComplete = { response ->
                        UserStore.token = response.token
                        UserStore.user = response.user
                    },
                    loginEvent = {
                        props.onLoggedIn()
                        request.history.push("/")
                    }
                )
            }

            route<RProps>("/requestpasswordreset") { props ->
                val email = props.location.params["email"]?.urlDecode() ?: ""
                requestPasswordReset(email, goBack = {
                    props.history.push("/")
                })
            }

            route<RProps>("/") { request ->
                login(
                    requestPasswordReset = {
                        request.history
                            .push("/requestpasswordreset" + if (it.isNotBlank()) "?email=${it.urlEncode()}" else "")
                    },
                    loggedIn = {
                        props.onLoggedIn()
                    }
                )
            }

        }
    }
}