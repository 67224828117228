package de.geomobile.portal.auth

import de.geomobile.portal.utils.Interceptor
import org.w3c.fetch.Response

class AuthenticationProviderInterceptor(val tokenProvider: () -> String?) : Interceptor {
    override suspend fun intercept(chain: Interceptor.Chain): Response {
        val token = tokenProvider()

        val request = if (token != null)
            chain.request.copy(headers = chain.request.headers + ("Authorization" to "Bearer $token"))
        else
            chain.request

        return chain.proceed(request)
    }
}