package de.geomobile.portal.auth

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.form.mFormControl
import com.ccfraser.muirwik.components.form.mFormGroup
import com.ccfraser.muirwik.components.input.mInput
import com.ccfraser.muirwik.components.input.mInputLabel
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.styles.up
import de.geomobile.portal.PortalUserDTO
import de.geomobile.portal.UserStore
import de.geomobile.portal.errorhandling.ApiException
import de.geomobile.portal.errorhandling.isInvalidSessionError
import de.geomobile.portal.restApi
import de.geomobile.portal.screen
import de.geomobile.portal.utils.CComponent
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.dom.div
import react.setState
import styled.css
import styled.styledDiv
import styled.styledImg
import kotlin.browser.document

fun RBuilder.login(
    loggedIn: () -> Unit,
    requestPasswordReset: (email: String) -> Unit
) = child(Login::class) {
    attrs.loggedIn = loggedIn
    attrs.requestPasswordReset = requestPasswordReset
}

class Login : CComponent<Login.Props, Login.State>() {

    private var job: Job = Job()

    interface Props : RProps {
        var loggedIn: () -> Unit
        var requestPasswordReset: (email: String) -> Unit
    }

    class State(
        var email: String = "",
        var password: String = "",
        var loading: Boolean = false,
        var error: String? = null
    ) : RState

    init {
        state = State()
    }

    override fun RBuilder.render() = screen {
        themeContext.Consumer { currentTheme ->
            val loading = state.loading
            val error = state.error

            mAppBar(position = MAppBarPosition.absolute, color = MColor.inherit) {
                css {
                    zIndex = currentTheme.zIndex.drawer + 1
                    display = Display.block
                }
                styledImg(alt = "Creactive Citizen Logo", src = "/ivanto_logo_rgb.png") {
                    css {
                        margin(horizontal = LinearDimension.auto)
                        height = 80.px
                        display = Display.block
                    }
                }
            }

            styledDiv {
                css {
                    width = 100.pct
                    display = Display.block
                    marginLeft = 2.spacingUnits
                    marginRight = 2.spacingUnits
                    marginTop = 80.px
                    media(currentTheme.breakpoints.up(Breakpoint.sm)) {
                        width = 400.px
                        marginLeft = LinearDimension.auto
                        marginRight = LinearDimension.auto
                    }
                }

                document.onkeydown = {
                    if (it.keyCode == 13) // User hits enter
                        tryLogin()
                }

                mPaper {
                    css {
                        marginTop = 8.spacingUnits
                        marginBottom = 1.spacingUnits
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        alignItems = Align.center
                        padding(2.spacingUnits, 3.spacingUnits, 3.spacingUnits)
                    }
                    mAvatar {
                        css {
                            margin(2.spacingUnits)
                            color = Colors.white
                            backgroundColor = Color(currentTheme.palette.secondary.main)
                        }
                        mIcon("lock")
                    }
                    mTypography(text = "Login", variant = MTypographyVariant.h5)
                    mFormGroup {
                        css {
                            marginTop = 1.spacingUnits
                            width = 100.pct
                        }
                        mFormControl(margin = MMargin.normal, required = true, fullWidth = true, disabled = loading) {
                            mInputLabel(caption = "E-Mail", htmlFor = "email")
                            mInput(
                                name = "email",
                                id = "email",
                                autoComplete = "email",
                                autoFocus = true,
                                onChange = {
                                    val value = it.targetInputValue
                                    setState { email = value }
                                }
                            )
                        }
                        mFormControl(margin = MMargin.normal, required = true, fullWidth = true, disabled = loading) {
                            mInputLabel(caption = "Passwort", htmlFor = "password")
                            mInput(
                                name = "password",
                                type = InputType.password,
                                id = "password",
                                autoComplete = "current-password",
                                onChange = {
                                    val value = it.targetInputValue
                                    setState { password = value }
                                }
                            )
                        }

                        if (loading) {
                            mCircularProgress {
                                css {
                                    marginTop = 3.spacingUnits
                                    marginLeft = LinearDimension.auto
                                    marginRight = LinearDimension.auto
                                }
                            }
                        } else {
                            mButton(
                                fullWidth = true,
                                variant = MButtonVariant.contained,
                                color = MColor.primary,
                                caption = "Anmelden",
                                onClick = { tryLogin() }
                            ) {
                                css { marginTop = 3.spacingUnits }
                            }
                        }

                        mButton(
                            fullWidth = true,
                            caption = "Passwort vergessen?",
                            onClick = { props.requestPasswordReset(state.email) }
                        ) {
                            css { marginTop = 3.spacingUnits }
                        }

                        if (error != null) {
                            mSnackbar(
                                error,
                                open = true,
                                horizAnchor = SnackbarHorizAnchor.center,
                                autoHideDuration = 4000,
                                onClose = { _, _ -> setState { this.error = null } },
                                action = RBuilder().div {
                                    mIconButton(
                                        "close",
                                        onClick = { setState { this.error = null } },
                                        color = MColor.inherit
                                    )
                                }
                            )
                        }
                    }
                }
            }
        }
    }

    private fun tryLogin() {
        job.cancel()
        job = launch {
            setState { loading = true }
            try {
                val response = withContext(Dispatchers.Default) {
                    restApi.post(
                        path = "/login",
                        body = Json.stringify(
                            LoginDTO.serializer(), LoginDTO(
                                email = state.email,
                                password = state.password
                            )
                        ),
                        serializer = LoginResponseDTO.serializer()
                    )
                }

                UserStore.token = response.token
                UserStore.user = response.user
                props.loggedIn()

            } catch (e: ApiException) {
                println("LOGIN ERROR: $e")

                val error = if (e.apiError.isInvalidSessionError)
                    "Ungültige Anmeldedaten"
                else
                    e.message

                setState {
                    this.error = error
                    this.loading = false
                }
            }

        }
    }
}

@Serializable
private data class LoginDTO(
    val email: String,
    val password: String
)

@Serializable
data class LoginResponseDTO(
    val user: PortalUserDTO,
    val token: String
)
