package de.geomobile.portal.auth

import de.geomobile.portal.errorhandling.ApiException
import de.geomobile.portal.errorhandling.isInvalidSessionError
import de.geomobile.portal.utils.Interceptor
import org.w3c.fetch.Response

class AuthenticationRequiredObserver(val onAuthenticationRequired: () -> Unit) : Interceptor {

    override suspend fun intercept(chain: Interceptor.Chain): Response =
        try {
            chain.proceed(chain.request)
        } catch (e: ApiException.ClientError) {
            if (e.apiError.isInvalidSessionError) {
                onAuthenticationRequired()
            }
            throw e
        }
}