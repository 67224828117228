package de.geomobile.portal.appusers

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.table.mTable
import com.ccfraser.muirwik.components.table.mTableBody
import de.geomobile.portal.PortalUserDTO
import de.geomobile.portal.articles.row
import de.geomobile.portal.screen
import de.geomobile.portal.screenContent
import de.geomobile.portal.screenToolbar
import de.geomobile.portal.restApi
import de.geomobile.portal.state
import de.geomobile.portal.utils.CComponent
import de.geomobile.portal.utils.mToolbarTitle2
import de.geomobile.portal.utils.toText
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import react.RBuilder
import react.RProps
import react.RState
import react.dom.div
import react.setState
import styled.css

fun RBuilder.appUserDetail(
    userId: String,
    goBack: () -> Unit
) = child(AppUserDetail::class) {
    attrs.userId = userId
    attrs.goBack = goBack
}

class AppUserDetail : CComponent<AppUserDetail.Props, AppUserDetail.State>() {

    private var blockJob: Job = Job()
    private var loadJob: Job = Job()

    interface Props : RProps {
        var userId: String
        var goBack: () -> Unit
    }

    class State(
        var user: AppUserDTO? = null
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        loadUser()
    }

    private fun loadUser() {
        loadJob.cancel()
        loadJob = launch {
            val user = withContext(Dispatchers.Default) {
                restApi.get("/appusers/${props.userId}", AppUserDTO.serializer())
            }

            setState { this.user = user }
        }
    }

    override fun RBuilder.render() = screen {
        val user = state.user

        screenToolbar {

            mIconButton(
                "arrow_back",
                color = MColor.inherit,
                onClick = { props.goBack() })
            mToolbarTitle2(user?.email ?: "")
        }

        screenContent {

            css { padding(4.spacingUnits) }

            mCard(raised = false) {
                css {
                    maxWidth = 1000.px
                    margin(horizontal = LinearDimension.auto)
                }

                mCardContent {
                    if (user == null)
                        mCircularProgress { css { display = Display.block; margin(LinearDimension.auto) } }
                    else
                        mTable {

                            mTableBody {

                                row("E-Mail") { mTypography(user.email) }
                                row("Name") { mTypography(user.name) }
                                row("Geschlecht") { mTypography(user.gender?.text ?: "-") }
                                row("Geburtstag") { mTypography(user.birthday?.toText() ?: "-") }
                                row("Beruf") { mTypography(user.profession ?: "-") }
                                row("Höchster Bildungsabschluss") { mTypography(user.education?.text ?: "-") }
                                row("Wohnort") { mTypography(user.habitation?.text ?: "-") }
                                row("Gesperrt") {
                                    mTypography(
                                        text = if (user.blocked) "Ja" else "Nein",
                                        variant = MTypographyVariant.body1
                                    ) { css { display = Display.inlineBlock; marginRight = 3.spacingUnits } }

                                    mButton(if (user.blocked) "Entsperren" else "Sperren", onClick = { toggleBlock(!user.blocked) })
                                }
                            }
                        }
                }
            }
        }
    }

    private fun toggleBlock(block: Boolean) {
        blockJob.cancel()
        blockJob = launch {
            withContext(Dispatchers.Default) {
                if (block)
                    restApi.put("/appusers/${props.userId}/block")
                else
                    restApi.delete("/appusers/${props.userId}/block")
            }

            loadUser()
        }
    }
}