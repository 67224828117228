package de.geomobile.portal.articles

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActionArea
import de.geomobile.portal.currentTheme
import de.geomobile.portal.utils.toText
import kotlinx.css.*
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledImg


fun RBuilder.articleCard(
    article: ArticleSmall,
    onClick: () -> Unit
) {
    mCard(raised = false) {

        css {
            margin(1.spacingUnits)
            height = 160.px
            when {
                article.releasedAt == null -> backgroundColor = Color.lightGray
                article.commentStats.created > 0 ->
                    backgroundColor = Color(currentTheme.palette.secondary.light).lighten(50)
            }
        }

        mCardActionArea(
            onClick = { onClick() }
        ) {

            css {
                height = 160.px
                display = Display.flex
                flexDirection = FlexDirection.row
            }

            styledImg(src = article.imageUrl) {
                css {
                    height = 160.px
                    width = 160.px
                    flexShrink = 0.0
                    objectFit = ObjectFit.cover
                }
            }


            styledDiv {
                css {
                    flex(1.0, 1.0, FlexBasis.auto)
                    overflow = Overflow.hidden
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    padding(2.spacingUnits)
                    position = Position.relative
                    height = 160.px
                }

                if (article.releasedAt == null)
                    mTypography("ENTWURF", variant = MTypographyVariant.h6) {
                        css {
                            color = Color.gray
                            position = Position.absolute
                            right = 2.spacingUnits
                            top = 2.spacingUnits
                        }
                    }
                mTypography(article.title, variant = MTypographyVariant.h6)
                mTypography(article.createdAt.toText(), variant = MTypographyVariant.caption)
                mTypography(article.primer, noWrap = true)
                styledDiv {
                    css {
                        display = Display.flex
                        flexGrow = 1.0
                        alignItems = Align.flexEnd
                        children {
                            margin(horizontal = 1.spacingUnits)
                            firstChild { marginLeft = 0.px }
                            lastChild { marginRight = 0.px }
                        }
                    }
                    infoIcon(
                        icon = "attach_file",
                        info = article.attachmentCount.toString(),
                        tooltip = "Anhänge",
                        visible = true
                    )

                    styledDiv { css.flexGrow = 1.0 }

                    infoIcon(
                        icon = "thumb_up",
                        info = article.ratings.positive.toString(),
                        tooltip = "Positive Bewertungen",
                        visible = article.ratingEnabled
                    )

                    infoIcon(
                        icon = "thumb_down",
                        info = article.ratings.negative.toString(),
                        tooltip = "Negative Bewertungen",
                        visible = article.ratingEnabled
                    )

                    styledDiv { css.flexGrow = 1.0 }

                    infoIcon(
                        icon = "comment",
                        info = article.commentStats.total.toString(),
                        tooltip = "Kommentare",
                        visible = article.commentsEnabled
                    )

                    val newCount = article.commentStats.created
                    infoIcon(
                        icon = "announcement",
                        info = newCount.toString(),
                        tooltip = "Nicht freigegebene Kommentare",
                        color = if (newCount > 0) currentTheme.palette.secondary.main else currentTheme.palette.text.disabled,
                        visible = article.commentsEnabled
                    )
                }

            }
        }

    }


}

fun RBuilder.infoIcon(
    icon: String,
    info: String,
    tooltip: String,
    color: String = currentTheme.palette.primary.main,
    visible: Boolean
) = mTooltip(tooltip) {
    styledDiv {
        css {
            display = Display.flex
            visibility = if (visible) Visibility.visible else Visibility.hidden
        }
        mIcon(icon) {
            css {
                marginRight = 1.spacingUnits
                this.color = Color(color)
            }
        }
        mTypography(info, color = MTypographyColor.inherit) {
            css {
                this.color = Color(color)
            }
        }
    }
}

