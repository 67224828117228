package de.geomobile.portal.articles

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.*
import com.ccfraser.muirwik.components.dialog.DialogMaxWidth
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.list.MListItemProps
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.table.*
import de.geomobile.portal.*
import de.geomobile.portal.utils.CComponent
import de.geomobile.portal.utils.toText
import kotlinx.coroutines.*
import kotlinx.css.*
import kotlinx.css.properties.TextDecoration
import kotlinx.css.properties.TextDecorationLine
import kotlinx.css.properties.borderLeft
import kotlinx.css.properties.borderTop
import react.*
import styled.*


fun RBuilder.articleComments(
    article: ArticleFull,
    reload: () -> Unit
) = child(ArticleComments::class) {
    attrs.article = article
    attrs.reload = reload
}

sealed class Entry {
    data class CommentEntry(
        val level: Int,
        val comment: Comment
    ) : Entry()

    data class AddEntry(
        val level: Int,
        val parent: Comment?
    ) : Entry()
}

class ArticleComments : CComponent<ArticleComments.Props, ArticleComments.State>() {

    interface Props : RProps {
        var article: ArticleFull
        var reload: () -> Unit
    }

    class State(
        var fullImageUrl: String? = null
    ) : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {
        mCard(raised = false) {
            css { width = 100.pct }
            mCardHeader("Kommentare")

            mList {
                for (entry in props.article.comments.flattened()) {

                    when (entry) {
                        is Entry.CommentEntry ->
                            mListItem {

                                css {
                                    borderTop(1.px, BorderStyle.solid, Color.lightGray)
                                    if (entry.level > 0) {
                                        borderLeft(1.px, BorderStyle.solid, Color.lightGray)
                                        marginLeft = (entry.level * 4).spacingUnits
                                        backgroundColor = Color("#efefef")
                                    }
                                    padding(
                                        left = 4.spacingUnits,
                                        right = 4.spacingUnits
                                    )
                                    width = LinearDimension.auto
                                }

                                comment(entry.comment, props.article.commentVotingEnabled)
                            }
                        is Entry.AddEntry -> if (entry.level < 2)
                            articleAddComment(
                                articleId = props.article.id,
                                parentId = entry.parent?.id,
                                reload = props.reload
                            )
                    }
                }
            }

            mDialog(
                open = state.fullImageUrl != null,
                maxWidth = DialogMaxWidth.disable,
                onClose = { _, _ -> setState { fullImageUrl = null } }
            ) {
                if (state.fullImageUrl != null) {

                    styledImg(src = state.fullImageUrl) {
                        css {
                        }
                    }

                }
            }
        }
    }

    private fun StyledElementBuilder<MListItemProps>.comment(comment: Comment, commentVotingEnabled: Boolean) {

        css {
            when (comment.state) {
                Comment.State.CREATED -> backgroundColor = Color(currentTheme.palette.secondary.light).lighten(50)
                Comment.State.REJECTED -> backgroundColor = Color.red
            }

        }

        styledDiv {

            css {
                display = Display.flex
                flexDirection = FlexDirection.column
                width = 100.pct
            }

            styledDiv {
                css {
                    display = Display.flex
                    alignItems = Align.center
                }

                styledDiv {
                    css {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        flexGrow = 1.0
                        marginBottom = 1.spacingUnits
                    }
                    if (comment.user != null) {
                        mLink(text = comment.user.name, href = if (comment.user.admin) "/admin/users/${comment.user.id}" else "/appusers/${comment.user.id}")
                        {
                            css {
                                fontWeight = FontWeight.bold
                            }
                        }
                    } else {
                        mTypography(text = "Anonym") {
                            css {
                                fontWeight = FontWeight.bold
                            }
                        }
                    }

                    mTypography(comment.createdAt.toText(), variant = MTypographyVariant.caption)

                }

                if (commentVotingEnabled) {
                    styledDiv {
                        css {
                            display = Display.flex
                            flexDirection = FlexDirection.row
                        }
                        mIcon("thumb_up") { css.margin(horizontal = 1.spacingUnits) }
                        mTypography(comment.voting.positive.toString())
                        mIcon("thumb_down") { css.margin(horizontal = 1.spacingUnits) }
                        mTypography(comment.voting.negative.toString())
                    }
                }

                if (comment.state != Comment.State.APPROVED)
                    mButton(
                        "Freigeben",
                        size = MButtonSize.small,
                        color = if (comment.state == Comment.State.CREATED) MColor.secondary else MColor.inherit,
                        variant = if (comment.state == Comment.State.CREATED) MButtonVariant.contained else MButtonVariant.outlined,
                        onClick = {
                            changeCommentState(
                                comment,
                                Comment.State.APPROVED
                            )
                        }) {
                        css { marginLeft = 2.spacingUnits }
                    }

                if (comment.state != Comment.State.REJECTED)
                    mButton(
                        "Sperren",
                        size = MButtonSize.small,
                        color = MColor.inherit,
                        variant = MButtonVariant.outlined,
                        onClick = {
                            changeCommentState(
                                comment,
                                Comment.State.REJECTED
                            )
                        }) {
                        css { marginLeft = 2.spacingUnits }
                    }
            }

            if (comment.image != null) {
                styledDiv {
                    css {
                        alignSelf = Align.center
                        width = LinearDimension.fitContent
                        position = Position.relative
                        hover {
                            children {
                                opacity = 1
                            }
                        }
                    }

                    styledImg(src = comment.image.smallSizeUrl) {

                        css {
                            maxWidth = 100.pct
                            maxHeight = 240.px

                            display = Display.block
                            objectFit = ObjectFit.scaleDown

                        }

                    }

                    styledDiv {
                        css {
                            opacity = 0
                            backgroundColor = whiteAlpha(0.4)
                            position = Position.absolute
                            top = 0.px
                            left = 0.px
                            width = 100.pct
                            height = 100.pct
                            display = Display.flex
                            justifyContent = JustifyContent.center
                            alignItems = Align.center
                        }
                        mButton("Anzeigen",
                            variant = MButtonVariant.contained,
                            color = MColor.primary,
                            onClick = {
                                setState { fullImageUrl = comment.image.fullSizeUrl }
                            }
                        )
                    }

                }

            }

            if (comment.comment != null) {
                mTypography(comment.comment) {
                    css {
                        whiteSpace = WhiteSpace.preWrap
                        if (comment.state == Comment.State.REJECTED)
                            textDecoration = TextDecoration(setOf(TextDecorationLine.lineThrough))
                    }
                }
            }

        }
    }

    private fun changeCommentState(comment: Comment, state: Comment.State) = launch {
        withContext(Dispatchers.Default) {
            restApi.post("/comments/${comment.id}/${if (state == Comment.State.APPROVED) "approve" else "reject"}")
        }

        props.reload()
    }

}

private fun List<Comment>.flattened(level: Int = 0, parent: Comment? = null): List<Entry> = this
    .sortedBy { it.createdAt }
    .flatMap { listOf(Entry.CommentEntry(level, it)) + it.subComments.flattened(level + 1, it) }
    .plusElement(Entry.AddEntry(level, parent))


fun RBuilder.row(
    label: String,
    verticalAlign: VerticalAlign = VerticalAlign.baseline,
    handler: StyledElementBuilder<out StyledProps>.() -> Unit
) = mTableRow {
    css.verticalAlign = verticalAlign
    mTableCell(padding = MTableCellPadding.dense) {
        css {
            border = "0"
        }
        mTypography(label, variant = MTypographyVariant.caption)
    }
    mTableCell(padding = MTableCellPadding.dense) {
        css {
            border = "0"
        }
        handler()
    }
}