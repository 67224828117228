package de.geomobile.portal.admin.users

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.table.mTable
import com.ccfraser.muirwik.components.table.mTableBody
import de.geomobile.portal.PortalUserDTO
import de.geomobile.portal.articles.row
import de.geomobile.portal.screen
import de.geomobile.portal.screenContent
import de.geomobile.portal.screenToolbar
import de.geomobile.portal.restApi
import de.geomobile.portal.state
import de.geomobile.portal.utils.CComponent
import de.geomobile.portal.utils.mToolbarTitle2
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import react.RBuilder
import react.RProps
import react.RState
import react.dom.div
import react.setState
import styled.css

fun RBuilder.adminUserDetail(
    userId: String,
    goBack: () -> Unit,
    edit: () -> Unit
) = child(AdminUserDetail::class) {
    attrs.userId = userId
    attrs.goBack = goBack
    attrs.edit = edit
}

class AdminUserDetail : CComponent<AdminUserDetail.Props, AdminUserDetail.State>() {

    private var sendInviteJob: Job = Job()

    interface Props : RProps {
        var userId: String
        var goBack: () -> Unit
        var edit: () -> Unit
    }

    class State(
        var user: PortalUserDTO? = null,
        var inviteSend: Boolean = false
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            val user = withContext(Dispatchers.Default) {
                restApi.get("/admin/users/${props.userId}", PortalUserDTO.serializer())
            }

            setState { this.user = user }
        }
    }

    override fun RBuilder.render() = screen {
        val user = state.user

        screenToolbar {

            mIconButton(
                "arrow_back",
                color = MColor.inherit,
                onClick = { props.goBack() })
            mToolbarTitle2(user?.email ?: "")
            mButton(
                "Bearbeiten",
                color = MColor.inherit,
                disabled = state.user == null,
                onClick = { props.edit() }
            )
        }

        screenContent {

            css { padding(4.spacingUnits) }

            mCard(raised = false) {
                css {
                    maxWidth = 1000.px
                    margin(horizontal = LinearDimension.auto)
                }

                mCardContent {
                    if (user == null)
                        mCircularProgress { css { display = Display.block; margin(LinearDimension.auto) } }
                    else
                        mTable {

                            mTableBody {

                                row("E-Mail") { mTypography(user.email) }
                                row("Name") { mTypography(user.name) }
                                row("Status") {
                                    mTypography(
                                        text = user.state(),
                                        variant = MTypographyVariant.body1
                                    ) { css { display = Display.inlineBlock; marginRight = 3.spacingUnits } }

                                    if (!user.active && user.invitedAt != null)
                                        mButton("Erneut einladen", onClick = { sendInvite() })
                                }
                            }
                        }
                }
            }
        }

        mSnackbar(
            message = "Einladung wurde versendet",
            open = state.inviteSend,
            horizAnchor = SnackbarHorizAnchor.center,
            autoHideDuration = 4000,
            onClose = { _, _ -> setState { this.inviteSend = false } },
            action = RBuilder().div {
                mIconButton(
                    "close",
                    onClick = { setState { this.inviteSend = false } },
                    color = MColor.inherit
                )
            }
        )
    }

    private fun sendInvite() {
        sendInviteJob.cancel()
        sendInviteJob = launch {
            val newUser = withContext(Dispatchers.Default) {
                restApi.post("/admin/users/${props.userId}/invite", serializer = PortalUserDTO.serializer())
            }

            setState {
                this.user = newUser
                this.inviteSend = true
            }
        }
    }
}