package de.geomobile.portal

import com.ccfraser.muirwik.components.mCssBaseline
import com.ccfraser.muirwik.components.styles.ThemeOptions
import com.ccfraser.muirwik.components.styles.createMuiTheme
import com.ccfraser.muirwik.components.themeContext
import com.ccfraser.muirwik.components.themeProvider
import com.ccfraser.muirwik.components.toolbarJsCssToPartialCss
import de.geomobile.portal.auth.AuthenticationProviderInterceptor
import de.geomobile.portal.auth.resetPassword
import de.geomobile.portal.errorhandling.ApiErrorInterceptor
import de.geomobile.portal.utils.RestApi
import de.geomobile.portal.utils.params
import react.RBuilder
import react.RProps
import react.dom.render
import react.router.dom.browserRouter
import react.router.dom.route
import react.router.dom.switch
import styled.StyleSheet
import styled.css
import styled.styledDiv
import kotlin.browser.document
import kotlin.browser.window

val REST_API_URL: String = window.asDynamic()._env_.REST_API_URL
val COMMENTS_ENABLED_DEFAULT: Boolean = window.asDynamic()._env_.COMMENTS_ENABLED_DEFAULT
val COMMENT_VOTING_ENABLED_DEFAULT: Boolean = window.asDynamic()._env_.COMMENT_VOTING_ENABLED_DEFAULT
val ALL_COMMENT_VOTING_DISABLED: Boolean = window.asDynamic()._env_.ALL_COMMENT_VOTING_DISABLED
val RATING_ENABLED_DEFAULT: Boolean = window.asDynamic()._env_.RATING_ENABLED_DEFAULT
val ALL_RATING_DISABLED: Boolean = window.asDynamic()._env_.ALL_RATING_DISABLED

external val navigator: dynamic

fun main() {
    window.onload = {
        val root = document.getElementById("root") ?: throw IllegalStateException()
        render(root) {
            println("REST_API_BASE_URL: $REST_API_URL")
            println("COMMENTS_ENABLED_DEFAULT: $COMMENTS_ENABLED_DEFAULT")
            println("COMMENT_VOTING_ENABLED_DEFAULT: $COMMENT_VOTING_ENABLED_DEFAULT")
            println("ALL_COMMENT_VOTING_DISABLED: $ALL_COMMENT_VOTING_DISABLED")
            println("RATING_ENABLED_DEFAULT: $RATING_ENABLED_DEFAULT")
            println("ALL_RATING_DISABLED: $ALL_RATING_DISABLED")

            mCssBaseline()

            themeProvider(currentTheme) {
                browserRouter {
                    switch {

                        route<RProps>("/app/resetpassword") { request ->
                            val token = request.location.params.getValue("token")
                            resetPassword(
                                api = restApi.copy(baseUrl = REST_API_URL + "/user"),
                                token = token,
                                resetComplete = {},
                                loginEvent = {}
                            )
                        }
                        route<RProps>("/") { request ->
                            browserRouter {
                                attrs.asDynamic().basename = "/portal"
                                mainFrame()
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RBuilder.spacer() {
    themeContext.Consumer { currentTheme ->
        val themeStyles = object : StyleSheet("ComponentStyles", isStatic = true) {
            val toolbar by css {
                toolbarJsCssToPartialCss(currentTheme.mixins.toolbar)
            }
        }

        // This puts in a spacer to get below the AppBar.
        styledDiv {
            css(themeStyles.toolbar)
        }
    }
}

val restApi = RestApi(
    baseUrl = REST_API_URL + "/portal",
    interceptors = listOf(
        UserStore.authenticationRequiredObserver,
        AuthenticationProviderInterceptor { UserStore.token },
        ApiErrorInterceptor
    )
)

@Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
private fun themeOptions(type: String) = (js(
    """({
                    palette: {
                        type: 'placeholder',
                        primary: {
                            main: 'placeholder'
                        },
                        secondary: {
                            main: 'placeholder',
                            contrastText: 'placeholder'
                        }
                    },
                    typography: {
                        useNextVariants: 'placeholder'
                    },
                     overrides: {
    // Style sheet name ⚛️
    MuiCard: {
      // Name of the rule
      root: {
        // Some CSS
        borderRadius: '16px',
      },
    },
  },
                })"""
) as ThemeOptions).apply {
    palette?.type = type
    palette?.primary.main = "#125e8c"
    palette?.secondary.main = "#57a617"
    palette?.secondary.contrastText = "#fff"
}

val currentTheme = createMuiTheme(
    themeOptions("light")
)