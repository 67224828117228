package de.geomobile.portal.articles

import kotlinx.serialization.Serializable

@Serializable
data class Survey(
    val active: Boolean,
    val showResultsInApp: Boolean,
    val showResultsOnlyToParticipants: Boolean,
    val participants: Int,
    val questions: List<Question>
) {

    @Serializable
    data class Question(
        val text: String,
        val singleChoice: Boolean,
        val answers: List<Answer>
    ) {

        @Serializable
        data class Answer(
            val text: String,
            val votes: Int = 0
        )
    }
}