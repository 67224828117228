package de.geomobile.portal

import com.ccfraser.muirwik.components.mCircularProgress
import de.geomobile.portal.utils.CComponent
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.mainFrame() = child(MainFrame::class) { }

class MainFrame : CComponent<MainFrame.Props, MainFrame.State>() {

    private var loadUserJob: Job = Job()

    interface Props : RProps {
    }

    class State(
        var user: PortalUserDTO? = null,
        var loggedIn: Boolean
    ) : RState

    init {
        state = State(
            loggedIn = UserStore.token != null
        )
    }

    override fun componentDidMount() {
        UserStore.reloadListeners.add(reloadListener)
        if (UserStore.token != null)
            loadUser()
    }

    override fun componentWillUnmount() {
        super.componentWillUnmount()
        UserStore.reloadListeners.remove(reloadListener)
    }

    private val reloadListener: () -> Unit = {
        setState {
            this.user = UserStore.user
            this.loggedIn = UserStore.token != null
        }
    }

    override fun RBuilder.render() {

            if (!state.loggedIn) {
                loggedOut(onLoggedIn = {
                    setState { loggedIn = true }
                    loadUser()
                })
            } else if (state.user == null) {
                mCircularProgress {
                    css { margin(LinearDimension.auto) }
                }
            } else {
                loggedIn(
                    user = state.user!!,
                    onLogout = {
                        setState { loggedIn = false }
                    })
            }

    }

    private fun loadUser() {
        setState { user = null }

        loadUserJob.cancel()
        loadUserJob = launch {
            val response = withContext(Dispatchers.Default) {
                restApi.get("/user", PortalUserDTO.serializer())
            }

            UserStore.user = response

            setState {
                user = response
            }
        }
    }
}