package de.geomobile.portal.utils

import kotlinx.coroutines.await
import kotlinx.coroutines.withTimeout
import kotlinx.serialization.ImplicitReflectionSerializer
import kotlinx.serialization.KSerializer
import kotlinx.serialization.json.Json
import org.w3c.fetch.RequestCredentials
import org.w3c.fetch.RequestInit
import org.w3c.fetch.Response
import kotlin.browser.window
import kotlin.js.json

@UseExperimental(ImplicitReflectionSerializer::class)
data class RestApi(
    val baseUrl: String,
//    val authorization: String,
    val timeout: Long = 10000,
    val interceptors: List<Interceptor> = emptyList()
) {

    suspend fun delete(path: String, body: dynamic = null) =
        request(
            Request(
                url = "$baseUrl$path",
                method = "DELETE",
                body = body,
                headers = mapOf("Content-Type" to "application/json; charset=utf-8")
            )
        ) {}

    suspend fun <T : Any> post(path: String, body: dynamic = null, serializer: KSerializer<T>): T =
        request(
            Request(
                url = "$baseUrl$path",
                method = "POST",
                body = body,
                headers = mapOf(
                    "Accept" to "application/json; charset=utf-8",
                    "Content-Type" to "application/json; charset=utf-8"
                )
            )
        ) {
            Json.nonstrict.parse(serializer, it.text().await())
        }

    suspend fun postReceiveText(path: String, body: dynamic = null): String =
        request(
            Request(
                url = "$baseUrl$path",
                method = "POST",
                body = body,
                headers = mapOf(
                    "Accept" to "application/json; charset=utf-8",
                    "Content-Type" to "application/json; charset=utf-8"
                )
            )
        ) {
            it.text().await()
        }

    suspend fun post(path: String, body: dynamic = null) =
        request(
            Request(
                url = "$baseUrl$path",
                method = "POST",
                body = body,
                headers = mapOf("Content-Type" to "application/json; charset=utf-8")
            )
        ) {}

    suspend fun upload(path: String, body: dynamic = null) =
        request(
            Request(
                url = "$baseUrl$path",
                method = "POST",
                body = body,
                headers = emptyMap()
            )
        ) { }

    suspend fun <T : Any> upload(path: String, body: dynamic = null, serializer: KSerializer<T>): T =
        request(
            Request(
                url = "$baseUrl$path",
                method = "POST",
                body = body,
                headers = emptyMap()
            )
        ) {
            Json.nonstrict.parse(serializer, it.text().await())
        }

    suspend fun <T : Any> put(path: String, body: dynamic = null, serializer: KSerializer<T>): T =
        request(
            Request(
                url = "$baseUrl$path",
                method = "PUT",
                body = body,
                headers = mapOf(
                    "Accept" to "application/json; charset=utf-8",
                    "Content-Type" to "application/json; charset=utf-8"
                )
            )
        ) {
            Json.nonstrict.parse(serializer, it.text().await())
        }

    suspend fun put(path: String, body: dynamic = null) =
        request(
            Request(
                url = "$baseUrl$path",
                method = "PUT",
                body = body,
                headers = mapOf("Content-Type" to "application/json; charset=utf-8")
            )
        ) {}

    suspend fun <T : Any> get(path: String, serializer: KSerializer<T>): T =
        request(
            Request(
                url = "$baseUrl$path",
                method = "GET",
                headers = mapOf("Accept" to "application/json; charset=utf-8")
            )
        ) {
            Json.nonstrict.parse(serializer, it.text().await())
        }

    suspend fun getRaw(path: String): String =
        request(
            Request(
                url = "$baseUrl$path",
                method = "GET",
                headers = mapOf("Accept" to "application/json; charset=utf-8")
            )
        ) { it.text().await() }

    suspend fun get(path: String) =
        request(Request(url = "$baseUrl$path", method = "GET", headers = emptyMap())) {}

    private suspend fun <T : Any> request(
        originalRequest: Request,
        resultProcessor: suspend (Response) -> T
    ): T {

        val allInterceptors = interceptors.plus(object : Interceptor {
            override suspend fun intercept(chain: Interceptor.Chain): Response {
                val request = chain.request
                val headers =
                    request.headers.map { (key, value) -> key to value }

                return withTimeout(timeMillis = timeout) {
                    window.fetch(request.url, object : RequestInit {
                        override var method: String? = request.method
                        override var body: dynamic = request.body
                        override var credentials: RequestCredentials? = "same-origin".asDynamic()
                        override var headers: dynamic = json(*headers.toTypedArray())
                    }).await()
                }
            }
        })

        val chain = RealInterceptorChain(originalRequest, allInterceptors, 0)

        val response = chain.proceed(originalRequest)

        return resultProcessor(response)
    }

}

data class Request(
    val url: String,
    val method: String,
    val headers: Map<String, String>,
    val body: dynamic = null
)

interface Interceptor {

    suspend fun intercept(chain: Chain): Response

    interface Chain {
        val request: Request

        suspend fun proceed(request: Request): Response
    }
}

class RealInterceptorChain(override val request: Request, val interceptors: List<Interceptor>, val index: Int) :
    Interceptor.Chain {

    override suspend fun proceed(request: Request): Response {
        return interceptors[index].intercept(RealInterceptorChain(request, interceptors, index + 1))
    }

}