package de.geomobile.portal.appusers

import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.mCircularProgress
import com.ccfraser.muirwik.components.mTypography
import com.ccfraser.muirwik.components.spacingUnits
import com.ccfraser.muirwik.components.table.*
import com.ccfraser.muirwik.components.targetValue
import de.geomobile.portal.Page
import de.geomobile.portal.restApi
import de.geomobile.portal.utils.CComponent
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.html.DIV
import react.*
import react.dom.div
import react.dom.p
import react.router.dom.RouteResultProps
import react.router.dom.route
import react.router.dom.switch
import styled.StyledDOMBuilder
import styled.css


fun RBuilder.appUserList(
    dashboard: RBuilder.(screenContent: StyledDOMBuilder<DIV>.() -> Unit) -> Unit
) = child(AppUserList::class) {
    attrs.dashboard = dashboard
}


private interface IdProp : RProps {
    var userId: String
}

class AppUserList : CComponent<AppUserList.Props, AppUserList.State>() {

    private var loadPageJob: Job = Job()

    interface Props : RProps {
        var dashboard: RBuilder.(screenContent: StyledDOMBuilder<DIV>.() -> Unit) -> Unit
    }

    class State(
        var users: Page<AppUserDTO>? = null,
        var page: Int = 0,
        var rowsPerPage: Int = 10,
        var count: Int = 0
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        loadPage(state.page, state.rowsPerPage)
    }

    private fun loadPage(page: Int, rowsPerPage: Int) {
        setState {
            this.page = page
            this.rowsPerPage = rowsPerPage
            this.users = null
        }
        loadPageJob.cancel()
        loadPageJob = launch {
            val users = withContext(Dispatchers.Default) {
                restApi.get("/appusers?limit=$rowsPerPage&start=${page * rowsPerPage}", Page.serializer(AppUserDTO.serializer()))
            }

            setState {
                this.users = users
                this.count = users.total
            }
        }
    }

    override fun RBuilder.render() {

        switch {

            route<RProps>("/appusers", exact = true) { request ->
                buildElement {
                    with(props) {
                        dashboard {
                            articleList(request)
                        }
                    }
                }
            }

            route<IdProp>("/appusers/:userId") { request ->
                val id = request.match.params.userId
                appUserDetail(
                    userId = id,
                    goBack = { request.history.goBack() }
                )
            }


        }

    }

    private fun StyledDOMBuilder<DIV>.articleList(request: RouteResultProps<RProps>) {

        val users = state.users

        css { padding(4.spacingUnits) }

        mCard(raised = false) {
            css {
                maxWidth = 1000.px
                margin(horizontal = LinearDimension.auto)
            }
            mTable {
                mTableHead {
                    mTableRow {
                        mTableCell { +"E-Mail" }
                        mTableCell(align = MTableCellAlign.right) { +"Name" }
                    }
                }
                mTableBody {
                    if (users == null) {
                        mTableRow {
                            mTableCell(colSpan = 99, align = MTableCellAlign.center) {
                                mCircularProgress { css { margin(2.spacingUnits) } }
                            }
                        }
                    } else if (users.total == 0) {
                        mTableRow {
                            mTableCell(colSpan = 99, align = MTableCellAlign.center) {
                                +"empty"
                            }
                        }
                    } else {
                        for (user in users.rows) {
                            mTableRow(
                                key = user.email,
                                hover = true,
                                onClick = {
                                    request.history.push("/appusers/${user.id}")
                                }
                            ) {
                                mTableCell { +user.email }
                                mTableCell(align = MTableCellAlign.right) { +(user.name) }
                            }
                        }
                    }
                }
            }
            mTablePagination(
                labelRowsPerPage = buildElements { p { +"Zeilen pro Seite" } },
                count = state.count,
                rowsPerPage = state.rowsPerPage,
                page = state.page,
                onChangePage = { _, page ->
                    loadPage(page = page, rowsPerPage = state.rowsPerPage)
                },
                onChangeRowsPerPage = { event ->
                    loadPage(page = 0, rowsPerPage = event.targetValue as Int)
                }
            )
        }
    }

}
