package de.geomobile.portal.appusers

import de.geomobile.portal.time.LocalDate
import kotlinx.serialization.Serializable

@Serializable
enum class Gender(val text: String) {
    MALE("Männlich"), FEMALE("Weiblich"), OTHER("Divers")
}

@Serializable
enum class Habitation(val text: String) {
    OLPE("Olpe: Stadt"), OLPEOTHERS("Olpe: Anderer Stadtteil"), ATTENDORN("Attendorn"),
    BILSTEIN("Bilstein"), DROLSHAGEN("Drolshagen"), KIRCHHUNDEM("Kirchhundem"),
    WENDEN("Wenden"), OTHERS("nicht aus dem Landkreis Olpe")
}

@Serializable
enum class Education(val text: String) {
    HAUPTSCHULE("Hauptschule"), Realschule("Realschule"), POLYSCHULE("Polytechnische Oberschule"),
    FACHHOCHSCHULREIFE("Fachholschulreife"), ABITUR("Gymnasium/Gesamtschule (Abitur)"),
    UNKNOWN("ohne Abschluss / unbekannt"), LEHRE("Lehre / FacharbeiterIn"),
    FACHSCHULE("MeisterIn / Fachschule"), UNI("(Fach-)Hochschule / Universität "), PROMOTION("Promotion"),
    OTHERS("Sonstiges")
}

@Serializable
data class AppUserDTO(
    val id: String,
    val email: String,
    val name: String,

    val gender: Gender? = null,
    val birthday: LocalDate? = null,
    val profession: String? = null,

    val habitation: Habitation? = null,
    val education: Education? = null,

    val blocked: Boolean
)