package de.geomobile.portal.auth

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.form.mFormControl
import com.ccfraser.muirwik.components.form.mFormGroup
import com.ccfraser.muirwik.components.input.mInput
import com.ccfraser.muirwik.components.input.mInputLabel
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.styles.up
import de.geomobile.portal.errorhandling.ApiException
import de.geomobile.portal.errorhandling.isInvalidSessionError
import de.geomobile.portal.screen
import de.geomobile.portal.utils.CComponent
import de.geomobile.portal.utils.RestApi
import kotlinx.coroutines.*
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.dom.div
import react.setState
import styled.css
import styled.styledDiv
import styled.styledImg
import kotlin.browser.document

fun RBuilder.resetPassword(
    api: RestApi,
    token: String,
    resetComplete: (LoginResponseDTO) -> Unit,
    loginEvent: () -> Unit
) = child(ResetPassword::class) {
    attrs.api = api
    attrs.token = token
    attrs.resetComplete = resetComplete
    attrs.loginEvent = loginEvent
}

class ResetPassword : CComponent<ResetPassword.Props, ResetPassword.State>() {

    private var job: Job = Job()

    interface Props : RProps {
        var api: RestApi
        var token: String
        var resetComplete: (LoginResponseDTO) -> Unit
        var loginEvent: () -> Unit
    }

    class State(
        var email: String = "",
        var password: String = "",
        var passwordCheck: String = "",
        var loading: Boolean = true,
        var error: String? = null,
        var success: Boolean = false
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {

            val email = withContext(Dispatchers.Default) {
                props.api.getRaw("/resetpassword?token=${props.token}")
            }

            setState {
                this.loading = false
                this.email = email
            }
        }
    }

    override fun RBuilder.render() = screen {
        themeContext.Consumer { currentTheme ->
            val loading = state.loading
            val error = state.error

            mAppBar(position = MAppBarPosition.absolute, color = MColor.inherit) {
                css {
                    zIndex = currentTheme.zIndex.drawer + 1
                    display = Display.block
                }
                styledImg(alt = "Creactive Citizen Logo", src = "/ivanto_logo_rgb.png") {
                    css {
                        margin(horizontal = LinearDimension.auto)
                        height = 80.px
                        display = Display.block
                    }
                }
            }

            styledDiv {
                css {
                    width = 100.pct
                    display = Display.block
                    marginLeft = 2.spacingUnits
                    marginRight = 2.spacingUnits
                    marginTop = 80.px
                    media(currentTheme.breakpoints.up(Breakpoint.sm)) {
                        width = 400.px
                        marginLeft = LinearDimension.auto
                        marginRight = LinearDimension.auto
                    }
                }

                document.onkeydown = {
                    if (it.keyCode == 13) // User hits enter
                        tryReset()
                }

                mPaper {
                    css {
                        marginTop = 8.spacingUnits
                        marginBottom = 1.spacingUnits
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        alignItems = Align.center
                        padding(2.spacingUnits, 3.spacingUnits, 3.spacingUnits)
                    }

                    if (state.success) {

                        mAvatar {
                            css {
                                margin(2.spacingUnits)
                                color = Colors.white
                                backgroundColor = Color(currentTheme.palette.secondary.main)
                            }
                            mIcon("check")
                        }
                        mTypography(text = "Neues Passwort wurde gesetzt", variant = MTypographyVariant.h5)

                    } else {

                        mAvatar {
                            css {
                                margin(2.spacingUnits)
                                color = Colors.white
                                backgroundColor = Color(currentTheme.palette.secondary.main)
                            }
                            mIcon("vpn_key")
                        }
                        mTypography(text = "Neues Passwort setzen", variant = MTypographyVariant.h5)
                        mFormGroup {
                            css {
                                marginTop = 1.spacingUnits
                                width = 100.pct
                            }
                            mFormControl(margin = MMargin.normal, required = true, fullWidth = true, disabled = true) {
                                mInputLabel(caption = "E-Mail", htmlFor = "email")
                                mInput(
                                    value = state.email,
                                    name = "email",
                                    id = "email",
                                    autoComplete = "email"
                                )
                            }
                            mFormControl(
                                margin = MMargin.normal,
                                required = true,
                                fullWidth = true,
                                disabled = loading
                            ) {
                                mInputLabel(caption = "Passwort", htmlFor = "password")
                                mInput(
                                    name = "password",
                                    type = InputType.password,
                                    id = "password",
                                    autoFocus = true,
                                    onChange = {
                                        val value = it.targetInputValue
                                        setState { password = value }
                                    }
                                )
                            }
                            mFormControl(
                                margin = MMargin.normal,
                                required = true,
                                fullWidth = true,
                                disabled = loading
                            ) {
                                mInputLabel(caption = "Passwort wiederholen", htmlFor = "password")
                                mInput(
                                    name = "password",
                                    type = InputType.password,
                                    id = "password",
                                    onChange = {
                                        val value = it.targetInputValue
                                        setState { passwordCheck = value }
                                    }
                                )
                            }

                            if (loading) {
                                mCircularProgress {
                                    css {
                                        marginTop = 3.spacingUnits
                                        marginLeft = LinearDimension.auto
                                        marginRight = LinearDimension.auto
                                    }
                                }
                            } else {
                                mButton(
                                    fullWidth = true,
                                    variant = MButtonVariant.contained,
                                    color = MColor.primary,
                                    caption = "Speichern",
                                    onClick = { tryReset() }
                                ) {
                                    css { marginTop = 3.spacingUnits }
                                }
                            }

                            if (error != null) {
                                mSnackbar(
                                    error,
                                    open = true,
                                    horizAnchor = SnackbarHorizAnchor.center,
                                    autoHideDuration = 4000,
                                    onClose = { _, _ -> setState { this.error = null } },
                                    action = RBuilder().div {
                                        mIconButton(
                                            "close",
                                            onClick = { setState { this.error = null } },
                                            color = MColor.inherit
                                        )
                                    }
                                )
                            }
                        }
                    }
                }
            }
        }
    }

    private fun tryReset() {
        if (state.password != state.passwordCheck) {
            setState { this.error = "Die Passwörter stimmen nicht überein" }
            return
        }

        job.cancel()
        job = launch {
            setState { loading = true }
            try {
                val response = withContext(Dispatchers.Default) {
                    props.api.postReceiveText(
                        path = "/resetpassword",
                        body = Json.stringify(
                            ResetPasswordDTO.serializer(), ResetPasswordDTO(
                                token = props.token,
                                password = state.password
                            )
                        )
                    )
                }

                if (response.isNotBlank()) {
                    props.resetComplete(Json.parse(LoginResponseDTO.serializer(), response))
                }

                setState {
                    this.error = null
                    this.loading = false
                    this.success = true
                }
                delay(1000)
                props.loginEvent()

            } catch (e: ApiException) {
                println("LOGIN ERROR: $e")

                val error = if (e.apiError.isInvalidSessionError)
                    "Invalid login credentials"
                else
                    e.message

                setState {
                    this.error = error
                    this.loading = false
                }
            }

        }
    }
}

@Serializable
private data class ResetPasswordDTO(
    val token: String,
    val password: String
)
