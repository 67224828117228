package de.geomobile.portal.articles

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.MCardContentProps
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.card.mCardHeader
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.table.*
import de.geomobile.portal.*
import de.geomobile.portal.utils.CComponent
import de.geomobile.portal.utils.mToolbarTitle2
import de.geomobile.portal.utils.toText
import kotlinx.coroutines.*
import kotlinx.css.*
import kotlinx.serialization.Serializable
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.*
import kotlin.math.roundToInt

@Serializable
data class ArticleUpdate(
    val article: ArticleFull? = null,
    val lastUpdate: Long
)

fun RBuilder.articleDetail(
    articleId: Int,
    goBack: () -> Unit,
    reload: () -> Unit,
    edit: () -> Unit
) = child(ArticleDetail::class) {
    key = articleId.toString()
    attrs.articleId = articleId
    attrs.goBack = goBack
    attrs.reload = reload
    attrs.edit = edit
}

class ArticleDetail : CComponent<ArticleDetail.Props, ArticleDetail.State>() {

    private var pollJob: Job = Job()

    interface Props : RProps {
        var articleId: Int
        var goBack: () -> Unit
        var reload: () -> Unit
        var edit: () -> Unit
    }

    class State(
        var article: ArticleFull? = null
    ) : RState

    init {
        state = State()
    }

    private fun startPoll() {
        pollJob.cancel()
        pollJob = launch {

            var lastUpdate: Long? = null

            while (isActive) {

                val update = withContext(Dispatchers.Default) {
                    try {
                        restApi.get("/articles/${props.articleId}${lastUpdate?.let { "?lastUpdate=$it" } ?: ""}",
                            ArticleUpdate.serializer())
                    } catch (e: Throwable) {
                        println(e.message)
                        null
                    }
                }

                if (update != null) {
                    if (update.article != null) {
                        setState {
                            this.article = update.article
                        }
                    }
                    lastUpdate = update.lastUpdate
                }

                delay(5000)
            }
        }
    }

    override fun componentDidMount() {
        startPoll()
    }

    override fun RBuilder.render() = screen {
        screenToolbar {
            mIconButton(
                "arrow_back",
                color = MColor.inherit,
                onClick = { props.goBack() })
            mToolbarTitle2(state.article?.title ?: "Artikel")
            mButton("Bearbeiten", color = MColor.inherit, onClick = { props.edit() })
        }

        screenContent {

            css { padding(1.spacingUnits) }

            val article = state.article

            if (article == null) {
                mCircularProgress { }
            } else {
                mList {
                    css {
                        maxWidth = 1400.px
                        margin(horizontal = LinearDimension.auto)
                    }

                    mListItem {
                        mCard(raised = false) {
                            css { width = 100.pct }
                            mCardContent {
                                details(article)
                            }
                        }
                    }

                    if (article.ratingEnabled) {
                        mListItem {
                            mCard(raised = false) {
                                css { width = 100.pct }
                                mCardHeader("Bewertungen")
                                mCardContent {
                                    ratings(article.ratings)
                                }
                            }
                        }
                    }

                    mListItem {
                        articleSurvey(
                            articleId = article.id,
                            survey = article.survey,
                            reload = {
                                startPoll()
                                props.reload
                            }
                        )
                    }

                    if (article.commentsEnabled) {
                        mListItem {
                            articleComments(
                                article = article,
                                reload = {
                                    startPoll()
                                    props.reload()
                                }
                            )
                        }
                    }
                }
            }

        }

    }

    private fun RBuilder.details(article: ArticleFull) {
        styledDiv {
            css {
                display = Display.flex
            }

            styledDiv {
                css {
                    width = 50.pct
                    maxWidth = 400.px
                    flexShrink = 0.0
                    position = Position.relative
                }

                styledDiv {
                    css {
                        width = 100.pct
                        position = Position.relative
                        height = 0.px
                        paddingTop = 100.pct
                    }

                    styledImg(src = article.imageUrl) {
                        css {
                            position = Position.absolute
                            top = 0.px
                            left = 0.px
                            width = 100.pct
                            height = 100.pct
                            borderRadius = 8.px
                            objectFit = ObjectFit.cover
                        }
                    }
                }
            }

            styledDiv {
                css {
                    flex(1.0, 1.0, FlexBasis.auto)
                    padding(2.spacingUnits)
                }

                mTable {
                    mTableBody {
                        row(label = "Titel") {
                            mTypography(article.title, variant = MTypographyVariant.h6)
                        }
                        row(label = "Erstellt") {
                            mTypography(article.createdAt.toText())
                        }
                        row(label = "Veröffentlicht") {
                            css {
                                display = Display.flex
                                alignItems = Align.baseline
                            }
                            mTypography(article.releasedAt?.toText() ?: "ENTWURF") {
                                if (article.releasedAt == null)
                                    css {
                                        fontStyle = FontStyle.italic
                                        marginRight = 3.spacingUnits
                                    }
                            }
                            if (article.releasedAt == null)
                                mButton(
                                    "Veröffentlichen",
                                    variant = MButtonVariant.outlined,
                                    color = MColor.secondary,
                                    onClick = { changeRelease(true) })
                        }
                        row(label = "Intro") {
                            mTypography(article.primer.takeIf { it.isNotBlank() } ?: "-") {
                                css.whiteSpace = WhiteSpace.preWrap
                            }
                        }
                        row(label = "Text") {
                            mTypography(article.details.takeIf { it.isNotBlank() } ?: "-") {
                                css.whiteSpace = WhiteSpace.preWrap
                            }
                        }
                        row(label = "Anhänge") {
                            css {
                                display = Display.flex
                                flexWrap = FlexWrap.wrap
                            }
                            for (attachment in article.attachments) {
                                mLink(href = attachment.downloadUrl) {
                                    css {
                                        marginRight = 1.spacingUnits
                                        marginBottom = 1.spacingUnits
                                    }
                                    mChip(attachment.name) {
                                        css { cursor = Cursor.inherit }
                                    }
                                }
                            }

                            if (article.attachments.isEmpty())
                                mTypography("-")
                        }
                    }
                }
            }
        }
    }

    private fun StyledElementBuilder<MCardContentProps>.ratings(ratings: RatingStats) {
        css {
            display = Display.flex
            alignItems = Align.center
            justifyContent = JustifyContent.center
        }
        val count = ratings.positive + ratings.negative
        val positive =
            if (count > 0) (100 * ratings.positive.toFloat() / count).roundToInt() else 0
        val negative =
            if (count > 0) (100 * ratings.negative.toFloat() / count).roundToInt() else 0

        mIcon("thumb_up") {
            css {
                fontSize = 64.px
                marginRight = 16.px
                color = Color.darkGreen
            }
        }
        mTypography(
            "${ratings.positive} ($positive%)",
            variant = MTypographyVariant.h6
        )
        mIcon("thumb_down") {
            css {
                fontSize = 64.px
                marginRight = 16.px
                marginLeft = 32.px
                color = Color.darkRed
            }
        }
        mTypography(
            "${ratings.negative} ($negative%)",
            variant = MTypographyVariant.h6
        )
    }

    private fun changeRelease(release: Boolean) = launch {
        withContext(Dispatchers.Default) {
            restApi.post(
                "/articles/${props.articleId}/toggleRelease",
                body = release.toString()
            )
        }

        startPoll()
        props.reload()
    }

}