package de.geomobile.portal.utils

import com.ccfraser.muirwik.components.MTypographyColor
import com.ccfraser.muirwik.components.MTypographyVariant
import com.ccfraser.muirwik.components.mTypography
import kotlinx.css.flexGrow
import react.RBuilder
import react.ReactElement
import styled.css


fun RBuilder.mToolbarTitle2(text: String): ReactElement {
    return mTypography(text, variant = MTypographyVariant.h6, color = MTypographyColor.inherit, noWrap = true) { css { flexGrow = 1.0 }}
}