package de.geomobile.common.errorhandling

import kotlinx.serialization.*

@Serializable(with = ApiErrorCodes.Companion::class)
enum class ApiErrorCodes(val uuid: String) {
    // Common
    DEPRECATED_API("d4eb6d5a-a5e2-4a1c-bfa7-ff5c7c85c58a"),
    UNKNOWN_ERROR("436e8489-9a19-45e0-8883-598d0468f09d"),
    NOT_FOUND("7b44c655-39e1-42ad-aad1-13eb39247943"),

    // Auth
    LOGIN_FAILURE("055b30c6-11e3-48df-8f69-943f515880a4"),
    PASSWORD_RESET_FAILURE("062117e0-dfe0-4f22-8968-204bac864d97"),
    AUTHENTICATION_NEEDED("ad14437d-2808-411c-a009-2479e1a33850"),
    ;


    @Serializer(forClass = ApiErrorCodes::class)
    companion object : KSerializer<ApiErrorCodes> {

        override val descriptor: SerialDescriptor = PrimitiveDescriptor("ApiErrorCodes", PrimitiveKind.STRING)

        override fun serialize(encoder: Encoder, obj: ApiErrorCodes) = encoder.encodeString(obj.uuid)

        override fun deserialize(decoder: Decoder): ApiErrorCodes {
            val uuid = decoder.decodeString()
            return ApiErrorCodes.values().single { it.uuid == uuid }
        }
    }
}