package de.geomobile.portal.articles

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.table.mTable
import com.ccfraser.muirwik.components.table.mTableBody
import de.geomobile.portal.*
import de.geomobile.portal.utils.CComponent
import de.geomobile.portal.utils.mToolbarTitle2
import kotlinx.coroutines.*
import kotlinx.css.*
import kotlinx.css.properties.TextDecoration
import kotlinx.css.properties.TextDecorationLine
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import org.w3c.dom.asList
import org.w3c.files.File
import org.w3c.files.FileList
import org.w3c.files.FileReader
import org.w3c.files.get
import org.w3c.xhr.FormData
import react.RBuilder
import react.RProps
import react.RState
import react.dom.input
import react.setState
import styled.css
import styled.styledDiv
import styled.styledImg


fun RBuilder.articleEdit(
    articleId: Int? = null,
    goBack: () -> Unit
) = child(ArticleEdit::class) {
    attrs.articleId = articleId
    attrs.goBack = goBack
}

class ArticleEdit(props: Props) : CComponent<ArticleEdit.Props, ArticleEdit.State>(props) {

    private var job: Job = Job()

    interface Props : RProps {
        var articleId: Int?
        var goBack: () -> Unit
    }

    class State(
        var saving: Boolean = false,

        var title: String = "",
        var primer: String = "",
        var details: String = "",
        var released: Boolean = false,

        var imageFile: File? = null,
        var imageUrl: String? = null,

        var removedAttachments: List<String> = emptyList(),
        var attachmentFiles: List<File> = emptyList(),

        var attachments: List<Attachment> = emptyList(),

        var commentsEnabled: Boolean = COMMENTS_ENABLED_DEFAULT,
        var commentVotingEnabled: Boolean = COMMENT_VOTING_ENABLED_DEFAULT,
        var ratingEnabled: Boolean = RATING_ENABLED_DEFAULT
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        if (props.articleId != null) {
            job.cancel()
            job = launch {
                val article = withContext(Dispatchers.Default) {
                    restApi.get("/articles/${props.articleId}", ArticleUpdate.serializer()).article!!
                }

                setState {
                    title = article.title
                    primer = article.primer
                    details = article.details
                    released = article.releasedAt != null
                    imageUrl = article.imageUrl
                    attachments = article.attachments
                    commentsEnabled = article.commentsEnabled
                    commentVotingEnabled = article.commentVotingEnabled
                    ratingEnabled = article.ratingEnabled
                }
            }
        }
    }

    override fun RBuilder.render() = screen {

        screenToolbar {
            mToolbarTitle2(if (props.articleId != null) "Artikel bearbeiten" else "Neuer Artikel")

            mButton(
                "Abbrechen",
                color = MColor.inherit,
                onClick = { props.goBack() }
            ) {
                css { marginRight = 1.spacingUnits }
            }

            mButton("Speichern",
                disabled = state.saving || (props.articleId == null && state.imageFile == null),
                variant = MButtonVariant.contained,
                color = MColor.secondary,
                onClick = {
                    save(
                        title = state.title,
                        primer = state.primer,
                        details = state.details,
                        released = state.released,
                        imageFile = state.imageFile,
                        removedAttachments = state.removedAttachments,
                        newAttachmentFiles = state.attachmentFiles,
                        commentsEnabled = state.commentsEnabled,
                        commentVotingEnabled = state.commentVotingEnabled,
                        ratingEnabled = state.ratingEnabled
                    )
                }
            )
        }

        screenContent {

            css { padding(1.spacingUnits) }

            mList {
                css {
                    maxWidth = 1400.px
                    margin(horizontal = LinearDimension.auto)
                }

                mListItem {
                    mCard(raised = false) {
                        css { width = 100.pct }

                        mCardContent {


                            css {
                                display = Display.flex
                            }

                            styledDiv {
                                css {
                                    width = 50.pct
                                    maxWidth = 400.px
                                    flexShrink = 0.0
                                    position = Position.relative
                                }

                                styledDiv {
                                    css {
                                        width = 100.pct
                                        position = Position.relative
                                        height = 0.px
                                        paddingTop = 100.pct
                                    }

                                    styledImg(src = state.imageUrl) {
                                        css {
                                            position = Position.absolute
                                            top = 0.px
                                            left = 0.px
                                            width = 100.pct
                                            height = 100.pct
                                            borderRadius = 8.px
                                            objectFit = ObjectFit.cover
                                        }
                                    }

                                    styledDiv {

                                        css {
                                            position = Position.absolute
                                            bottom = 0.px
                                            left = 0.px
                                            width = 100.pct
                                            height = 20.pct
                                            backgroundColor = Color.black.withAlpha(0.7)
                                            borderBottomLeftRadius = 8.px
                                            borderBottomRightRadius = 8.px
                                            padding(1.spacingUnits)
                                            display = Display.flex
                                            alignItems = Align.center
                                            justifyContent = JustifyContent.center
                                            color = Color.white
                                        }

                                        input(
                                            name = "image",
                                            type = InputType.file
                                        ) {
                                            attrs.id = "image"
                                            attrs.accept = "image/*"
                                            attrs.onChangeFunction = {
                                                val value = it.target.asDynamic().files as FileList
                                                val reader = FileReader()
                                                val file = if (value.length > 0) value[0] else null

                                                if (file != null) {
                                                    reader.onloadend = {
                                                        setState {
                                                            imageFile = file
                                                            imageUrl = reader.result
                                                        }
                                                    }

                                                    reader.readAsDataURL(file)
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            styledDiv {
                                css {
                                    flex(1.0, 1.0, FlexBasis.auto)
                                    padding(2.spacingUnits)
                                }

                                mTable {
                                    mTableBody {
                                        row(label = "Titel", verticalAlign = VerticalAlign.middle) {
                                            mTextField(
                                                label = "",
                                                variant = MFormControlVariant.outlined,
                                                value = state.title,
                                                fullWidth = true,
                                                onChange = {
                                                    val value = it.targetInputValue
                                                    setState {
                                                        this.title = value
                                                    }
                                                }
                                            )
                                        }
                                        row(label = "Veröffentlichen", verticalAlign = VerticalAlign.middle) {
                                            mSwitch(
                                                checked = state.released,
                                                onChange = { _, checked ->
                                                    setState { this.released = checked }
                                                }
                                            )
                                        }
                                        row(label = "Intro", verticalAlign = VerticalAlign.middle) {
                                            mTextFieldMultiLine(
                                                label = "",
                                                variant = MFormControlVariant.outlined,
                                                value = state.primer,
                                                fullWidth = true,
                                                onChange = {
                                                    val value = it.targetInputValue
                                                    setState {
                                                        this.primer = value
                                                    }
                                                }
                                            )
                                        }
                                        row(label = "Text", verticalAlign = VerticalAlign.middle) {
                                            mTextFieldMultiLine(
                                                label = "",
                                                variant = MFormControlVariant.outlined,
                                                value = state.details,
                                                fullWidth = true,
                                                onChange = {
                                                    val value = it.targetInputValue
                                                    setState {
                                                        this.details = value
                                                    }
                                                }
                                            )
                                        }
                                        row(label = "Anhänge") {
                                            mList(disablePadding = true) {
                                                for (attachment in state.attachments) {
                                                    val removed = attachment.id in state.removedAttachments
                                                    mListItem {
                                                        css {
                                                            padding(vertical = 0.px)
                                                            if (removed) backgroundColor = Color.lightCoral
                                                        }
                                                        mTypography(attachment.name) {
                                                            css {
                                                                marginRight = 2.spacingUnits
                                                                if (removed)
                                                                    textDecoration =
                                                                        TextDecoration(setOf(TextDecorationLine.lineThrough))
                                                            }
                                                        }

                                                        mIconButton(
                                                            if (removed) "restore_from_trash" else "delete",
                                                            onClick = {
                                                                setState {
                                                                    if (attachment.id in this.removedAttachments)
                                                                        this.removedAttachments -= attachment.id
                                                                    else
                                                                        this.removedAttachments += attachment.id
                                                                }
                                                            }
                                                        )
                                                    }
                                                }
                                                for (attachment in state.attachmentFiles) {
                                                    mListItem {
                                                        css {
                                                            backgroundColor = Color.lightGreen
                                                        }
                                                        mTypography(attachment.name) {
                                                            css.fontStyle = FontStyle.italic
                                                        }
                                                    }
                                                }
                                                mListItem {
                                                    input(
                                                        name = "attachment",
                                                        type = InputType.file
                                                    ) {
                                                        attrs.id = "attachment"
                                                        attrs.multiple = true
                                                        attrs.onChangeFunction = {
                                                            val value = it.target.asDynamic().files as FileList
                                                            val files = value.asList()
                                                            setState {
                                                                attachmentFiles = files
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                        }
                                        row(label = "Kommentare aktivieren", verticalAlign = VerticalAlign.middle) {
                                            mSwitch(
                                                checked = state.commentsEnabled,
                                                onChange = { _, checked ->
                                                    setState { this.commentsEnabled = checked }
                                                }
                                            )
                                        }
                                        if (!ALL_COMMENT_VOTING_DISABLED) {
                                            row(
                                                label = "Kommentarbewertungen aktivieren",
                                                verticalAlign = VerticalAlign.middle
                                            ) {
                                                mSwitch(
                                                    checked = state.commentVotingEnabled,
                                                    onChange = { _, checked ->
                                                        setState { this.commentVotingEnabled = checked }
                                                    }
                                                )
                                            }
                                        }
                                        if (!ALL_RATING_DISABLED) {
                                            row(
                                                label = "Artikelbewertungen aktivieren",
                                                verticalAlign = VerticalAlign.middle
                                            ) {
                                                mSwitch(
                                                    checked = state.ratingEnabled,
                                                    onChange = { _, checked ->
                                                        setState { this.ratingEnabled = checked }
                                                    }
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private fun save(
        title: String,
        primer: String,
        details: String,
        released: Boolean,
        imageFile: File?,
        removedAttachments: List<String>,
        newAttachmentFiles: List<File>,
        commentsEnabled: Boolean,
        commentVotingEnabled: Boolean,
        ratingEnabled: Boolean
    ) = launch {
        setState { saving = true }

        withContext(Dispatchers.Default) {
            val id = props.articleId?.toString()

            val formData = FormData()
            formData.append("title", title)
            formData.append("primer", primer)
            formData.append("details", details)
            formData.append("released", released.toString())
            formData.append("removedAttachments", removedAttachments.joinToString(","))
            formData.append("commentsEnabled", commentsEnabled.toString())
            formData.append("commentVotingEnabled", commentVotingEnabled.toString())
            formData.append("ratingEnabled", ratingEnabled.toString())
            if (imageFile != null) {
                formData.append("image", imageFile)
            }
            for (file in newAttachmentFiles) {
                formData.append(file.name, file)
            }

            if (id != null) {
                restApi.upload("/articles/$id", body = formData, serializer = ArticleFull.serializer())
            } else {
                restApi.upload("/articles", body = formData, serializer = ArticleFull.serializer())
            }
        }

        setState { saving = false }
        props.goBack()
    }

}
