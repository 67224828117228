package de.geomobile.portal

import kotlinx.serialization.Serializable

@Serializable
data class Page<T>(
    val rows: List<T>,
    val start: Int,
    val limit: Int,
    val total: Int
)