package de.geomobile.portal.articles

import de.geomobile.portal.time.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class ArticleSmall(
        val id: Int,
        val title: String,
        val primer: String,
        val details: String,
        val imageUrl: String,
        val createdAt: LocalDateTime,
        val releasedAt: LocalDateTime? = null,
        val ratings: RatingStats,
        val commentStats: CommentStats,
        val attachmentCount: Int,
        val commentsEnabled: Boolean,
        val commentVotingEnabled: Boolean,
        val ratingEnabled: Boolean
)

@Serializable
data class ArticleFull(
        val id: Int,
        val title: String,
        val primer: String,
        val details: String,
        val imageUrl: String,
        val createdAt: LocalDateTime,
        val releasedAt: LocalDateTime? = null,
        val ratings: RatingStats,
        val comments: List<Comment>,
        val attachments: List<Attachment>,
        val survey: Survey? = null,
        val commentsEnabled: Boolean,
        val commentVotingEnabled: Boolean,
        val ratingEnabled: Boolean
)

@Serializable
data class CommentStats(
        val total: Int,
        val created: Int,
        val approved: Int
)

@Serializable
data class RatingStats(
        val positive: Int,
        val negative: Int,
        val own: Boolean? = null
)