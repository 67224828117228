package de.geomobile.portal.time

import kotlinx.serialization.*
import kotlin.js.Date

@Serializable(with = LocalDateSerializer::class)
data class LocalDate(val millis: Long) : Comparable<LocalDate> {

    companion object {
        fun now(): LocalDate = LocalDate(Date.now().toLong())
        fun parse(encoded: String) = LocalDate(Date.parse(encoded).toLong())
    }

    fun format(): String = jsDate.toISOString().take(10)

    override fun compareTo(other: LocalDate): Int = millis.compareTo(other.millis)

    val jsDate: Date get() = Date(millis)

    override fun toString(): String = format()
}

@Serializer(forClass = LocalDate::class)
object LocalDateSerializer : KSerializer<LocalDate> {

    override val descriptor: SerialDescriptor = PrimitiveDescriptor("WithCustomDefault", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, obj: LocalDate) {
        encoder.encodeString(obj.format())
    }

    override fun deserialize(decoder: Decoder): LocalDate {
        return LocalDate.parse(decoder.decodeString())
    }
}